import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardContent, CardMedia, Typography, Container, Box, IconButton, Chip, Stack, Button, Menu, MenuItem } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos, TrendingUp as TrendingUpIcon, ViewList as ViewListIcon, MoreVert as MoreVertIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import TrendModal from './TrendModal';  // Import the TrendModal component

const TrendsSlider = () => {
  const [trendsItems, setTrendsItems] = useState([]);
  const [filteredTrendsItems, setFilteredTrendsItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [open, setOpen] = useState(false);  // State to manage modal open/close
  const [selectedTrend, setSelectedTrend] = useState(null);  // State to hold the selected trend item
  const [anchorEl, setAnchorEl] = useState(null); // Anchor element for dropdown menu
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTrendsItems = async () => {
      try {
        const trendsResponse = await axios.get('/api/trend');
        const sortedTrendsItems = trendsResponse.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        const latestTrendsItems = sortedTrendsItems.slice(0, 5); // Get the latest 5 items
        const uniqueCategories = [...new Set(trendsResponse.data.flatMap(item => item.categories.split(',')))];
        setCategories(['All', ...uniqueCategories]);
        setTrendsItems(latestTrendsItems); // Use only the latest 5 items
        setFilteredTrendsItems(latestTrendsItems); // Use only the latest 5 items
      } catch (error) {
        console.error('Error fetching trends items:', error);
      }
    };
    
    fetchTrendsItems();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % filteredTrendsItems.length);
    }, 7000); // Slide transition every 7 seconds
    return () => clearInterval(interval);
  }, [filteredTrendsItems.length]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + filteredTrendsItems.length) % filteredTrendsItems.length);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % filteredTrendsItems.length);
  };

  const handleTrendsClick = (trendsItem) => {
    setSelectedTrend(trendsItem);
    setOpen(true);  // Open the modal when a trend is clicked
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setAnchorEl(null); // Close dropdown menu when a category is selected
    if (category === 'All') {
      setFilteredTrendsItems(trendsItems);
    } else {
      const filteredItems = trendsItems.filter((trendsItem) =>
        trendsItem.categories.split(',').includes(category)
      );
      setFilteredTrendsItems(filteredItems);
    }
    setCurrentIndex(0);  // Reset slider to the first item
  };

  const handleShowAllTrends = () => {
    navigate('/trends');  // Redirect to the trends page
  };

  const handleClose = () => {
    setOpen(false);  // Close the modal
    setSelectedTrend(null);  // Clear the selected trend when the modal is closed
  };

  const getCategoryStyles = (category) => {
    if (category.includes('National')) {
      return {
        cardBackground: 'linear-gradient(135deg, #004d00, #006600)',  // Dark green gradient
        chipBackground: '#006600',
        color: '#FFFFFF',
      };
    }
    if (category.includes('Regional')) {
      return {
        cardBackground: 'linear-gradient(135deg, #004d4d, #008080)',  // Teal gradient
        chipBackground: '#008080',  // Teal background for the chip
        color: '#FFFFFF',  // White text color
      };
    }
    
    if (category.includes('International')) {
      return {
        cardBackground: 'linear-gradient(135deg, #000000, #434343)',  // Black gradient
        chipBackground: '#434343',
        color: '#FFFFFF',
      };
    }
    return {
      cardBackground: 'linear-gradient(135deg, #434343, #000000)',  // Default black gradient
      chipBackground: '#434343',
      color: '#FFFFFF',
    };
  };

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget); // Set the anchor element for the dropdown menu
  };

  const handleMenuClose = () => {
    setAnchorEl(null); // Close the dropdown menu
  };

  return (
    <Container>
      <Box display="flex" alignItems="center" justifyContent="center" mb={4}>
        <TrendingUpIcon sx={{ mr: 2, fontSize: '2rem', color: '#FFD700' }} />
        <Typography variant="h4" component="h2" gutterBottom sx={{ color: '#FFD700', fontWeight: 'bold', letterSpacing: '2px', textTransform: 'uppercase' }}>
          Latest Trends
        </Typography>
      </Box>
      <Box position="relative" className="slider-container">
        <IconButton
          onClick={handlePrev}
          className="slider-button"
          sx={{ position: 'absolute', top: '50%', left: 0, color: '#FFD700' }}
        >
          <ArrowBackIos />
        </IconButton>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="absolute"
          left="35%"
          transform="translateX(-50%)"
          zIndex={1}
          bgcolor="rgba(0, 0, 0, 0.7)"
          p={1}
          borderRadius="10px"
        >
          <Stack direction="row" spacing={1}>
            {categories.slice(0, 3).map((category) => {
              const { chipBackground, color } = getCategoryStyles(category);  // Get the background and text color for each category
              return (
                <Chip
                  key={category}
                  label={category}
                  onClick={() => handleCategoryChange(category)}
                  sx={{
                    backgroundColor: chipBackground,  // Always apply the category's specific background color
                    color: color,  // Always apply the category's specific text color
                    fontWeight: selectedCategory === category ? 'bold' : 'normal',
                    cursor: 'pointer',
                    borderRadius: '8px',
                    px: 2,
                    py: 1,
                    '&:hover': {
                      transform: 'scale(1.05)',  // Slight hover effect for interaction feedback
                    },
                  }}
                />
              );
            })}
            {categories.length > 3 && (
              <>
                <IconButton
                  onClick={handleMoreClick}
                  sx={{
                    color: '#FFD700',
                    borderRadius: '8px',
                    padding: '6px',
                    backgroundColor: '#333',
                    '&:hover': {
                      backgroundColor: '#444',
                    },
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  PaperProps={{
                    style: {
                      backgroundColor: '#333',
                      color: '#FFD700',
                    },
                  }}
                >
                  {categories.slice(3).map((category) => {
                    const { chipBackground, color } = getCategoryStyles(category);  // Same logic for the dropdown menu
                    return (
                      <MenuItem
                        key={category}
                        onClick={() => handleCategoryChange(category)}
                        sx={{
                          backgroundColor: chipBackground,  // Always apply the category's specific background color
                          color: color,  // Always apply the category's specific text color
                          fontWeight: selectedCategory === category ? 'bold' : 'normal',
                          '&:hover': {
                            backgroundColor: chipBackground,  // Keep the same background color on hover
                            opacity: 0.9,  // Slight hover effect
                          },
                        }}
                      >
                        {category}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </>
            )}
          </Stack>
        </Box>

        <Box className="slider-track" sx={{ mt: 7 }} style={{ transform: `translateX(-${currentIndex * 300}px)` }}>
          {filteredTrendsItems.map((trendsItem, index) => (
            <Box
              key={trendsItem.id}
              className={`slider-card ${index === currentIndex ? 'active' : ''}`}
              onClick={() => handleTrendsClick(trendsItem)}
              sx={{
                minWidth: '300px',
                maxWidth: '300px',
                height: '350px',
                padding: '8px',
                cursor: 'pointer',
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: '0 4px 20px rgba(0,0,0,0.5)',
                },
              }}
            >
             <Card
  className="trends-card"
  sx={{
    width: '100%',
    height: '100%',
    borderRadius: '8px',
    overflow: 'hidden',
    background: getCategoryStyles(trendsItem.categories).cardBackground, // Apply the background based on category
    color: getCategoryStyles(trendsItem.categories).color, // Ensure text color matches the background
  }}
>
  <Box sx={{ position: 'relative', width: '100%', height: '180px' }}>
    <CardMedia
      component="img"
      sx={{ width: '100%', height: '100%', objectFit: 'cover', transition: 'transform 0.3s ease-in-out' }}
      image={`https://smmosaic.com/uploads/${trendsItem.media.split(',')[0]}`}
      alt={trendsItem.name}
      className="card-media"
    />
    <Chip
      label={trendsItem.categories}
      sx={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        backgroundColor: getCategoryStyles(trendsItem.categories).chipBackground, // Apply the chip background based on category
        color: getCategoryStyles(trendsItem.categories).color,
        fontWeight: 'bold',
      }}
    />
  </Box>
  <CardContent
    className="card-content"
    sx={{
      padding: '16px',
      color: getCategoryStyles(trendsItem.categories).color, // Ensure text color matches the background
    }}
  >
    <Typography
      gutterBottom
      variant="h6"
      component="div"
      sx={{ fontSize: '1rem', fontWeight: 'bold', lineHeight: '1.2em' }}
    >
      {trendsItem.name}
    </Typography>

  </CardContent>
</Card>

            </Box>
          ))}
        </Box>
        <IconButton
          onClick={handleNext}
          className="slider-button"
          sx={{ position: 'absolute', top: '50%', right: 0,  color: '#FFD700' }}
        >
          <ArrowForwardIos />
        </IconButton>
      </Box>
      <Box display="flex" justifyContent="center" mt={4}>
      <Button
  variant="contained"
  onClick={handleShowAllTrends}
  startIcon={<ViewListIcon />}
  sx={{
    background: 'linear-gradient(135deg, #004e92, #000428)', // Gradient background
    color: '#FFFFFF', // White text
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderRadius: '25px', // Rounded corners
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)', // Adds depth
    transition: 'all 0.3s ease', // Smooth transition for hover effects
    '&:hover': {
      background: 'linear-gradient(135deg, #003366, #001f33)', // Darker gradient on hover
      boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.5)', // Stronger shadow on hover
      transform: 'scale(1.05)', // Slight scale effect on hover
    },
  }}
>
  Show All Trends
</Button>

      </Box>

      {/* TrendModal Component */}
      <TrendModal open={open} handleClose={handleClose} trendItem={selectedTrend} />
    </Container>
  );
};

export default TrendsSlider;
