import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './components/LoginPage/LoginPage';
import AdminDashboard from './components/AdminDashboard/AdminDashboard';
import UserDashboard from './components/UserDashboard/UserDashboard';
import ArticlesPage from './components/UserDashboard/ArticlesPage';
import ArticleModal from './components/UserDashboard/ArticleModal';
import NewsPage from './components/UserDashboard/NewsPage';
import TrendPage from './components/UserDashboard/TrendPage';
import ProtectedRoute from './components/ProtectedRoute';  // Import the protected route component

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Public route for login */}
        <Route path="/" element={<LoginPage />} />

        {/* Protected route for admin - accessible only by users with admin role */}
        <Route 
          path="/admin/*" 
          element={
            <ProtectedRoute role="admin">
              <AdminDashboard />
            </ProtectedRoute>
          } 
        />

        {/* Protected routes for regular users */}
        <Route 
          path="/user" 
          element={
            <ProtectedRoute role="user">
              <UserDashboard />
            </ProtectedRoute>
          } 
        />

        <Route 
          path="/articles" 
          element={
            <ProtectedRoute role="user">
              <ArticlesPage />
            </ProtectedRoute>
          } 
        />

        <Route 
          path="/article/:id" 
          element={
            <ProtectedRoute role="user">
              <ArticleModal />
            </ProtectedRoute>
          } 
        />

        <Route 
          path="/news" 
          element={
            <ProtectedRoute role="user">
              <NewsPage />
            </ProtectedRoute>
          } 
        />

        <Route 
          path="/trends" 
          element={
            <ProtectedRoute role="user">
              <TrendPage />
            </ProtectedRoute>
          } 
        />
      </Routes>
    </Router>
  );
};

export default App;
