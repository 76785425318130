import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardContent, CardMedia, Typography, Container, Box, IconButton, Button } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos, Article as ArticleIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import './Slider.css';

const Slider = () => {
  const [articles, setArticles] = useState([]); // Initialize as an empty array
  const [currentIndex, setCurrentIndex] = useState(0); // Start at index 0 (latest article)
  const navigate = useNavigate();

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get('/api/article');
        if (response.data && response.data.length > 0) {
          // Sort articles by date (most recent first), then by another field (like 'id' or 'uploadTime')
          const sortedArticles = response.data.sort((a, b) => {
            const dateComparison = new Date(b.date) - new Date(a.date);
            if (dateComparison === 0) {
              // If dates are the same, sort by 'id' (or 'uploadTime') as the secondary criterion
              return b.id - a.id; // or compare timestamps if you have 'uploadTime'
            }
            return dateComparison;
          });
          setArticles(sortedArticles.slice(0, 5)); // Display the first 5 most recent articles
        } else {
          setArticles([]); // If no articles, ensure articles is an empty array
        }
      } catch (error) {
        console.error('Error fetching articles:', error);
        setArticles([]); // In case of error, set articles to an empty array
      }
    };
  
    fetchArticles();
  }, []);
  
  useEffect(() => {
    if (articles.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % articles.length);
      }, 7000);

      return () => clearInterval(interval);
    }
  }, [articles]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + articles.length) % articles.length);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % articles.length);
  };

  const handleViewAll = () => {
    navigate('/articles');
  };

  const handleArticleClick = (article) => {
    navigate(`/article/${article.id}`);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    });
  };
  
  
  return (
    <Container>
      <Box display="flex" alignItems="center" justifyContent="center" mb={4}>
        <ArticleIcon sx={{ mr: 2, fontSize: '2rem', color: '#FFD700' }} />
        <Typography variant="h4" component="h2" gutterBottom sx={{ color: '#FFD700', fontWeight: 'bold', letterSpacing: '2px', textTransform: 'uppercase' }}>
          Situational Awareness
        </Typography>
      </Box>
      
      {articles.length > 0 ? ( // Check if articles are available
        <>
          <Box position="relative" className="slider-container">
            <IconButton
              onClick={handlePrev}
              className="slider-button"
              sx={{ position: 'absolute', top: '50%', left: 0, color: '#FFD700' }}
            >
              <ArrowBackIos />
            </IconButton>
            <Box className="slider-track" style={{ transform: `translateX(-${currentIndex * 300}px)` }}>
              {articles.map((article, index) => (
                <Box
                  key={article.id}
                  className={`slider-card ${index === currentIndex ? 'active' : ''}`}
                  onClick={() => handleArticleClick(article)}
                >
                  <Card className="article-card">
                    <CardMedia
                      component="img"
                      height="180"
                      image={`/${article.image}`}
                      alt={article.title}
                      className="card-media"
                    />
                    <CardContent className="card-content">
                      <Typography gutterBottom variant="h6" component="div" sx={{ color: '#1b2839' }}>
                        {article.title}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" sx={{ color: '#666', mb: 1 }}>
                        {formatDate(article.date)}
                      </Typography>
                      
                    </CardContent>
                  </Card>
                </Box>
              ))}
            </Box>
            <IconButton
              onClick={handleNext}
              className="slider-button"
              sx={{ position: 'absolute', top: '50%', right: 0, color: '#FFD700' }}
            >
              <ArrowForwardIos />
            </IconButton>
          </Box>
          <Box display="flex" justifyContent="center" mt={2}>
            {articles.map((_, index) => (
              <span key={index} className={`indicator ${index === currentIndex ? 'active' : ''}`}></span>
            ))}
          </Box>
        </>
      ) : (
        <Typography variant="h6" align="center" color="textSecondary">
          No articles available.
        </Typography>
      )}

      <Box display="flex" justifyContent="center" mt={4}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleViewAll}
          startIcon={<ArticleIcon />}
          sx={{
            background: 'linear-gradient(135deg, #004e92, #000428)',
            color: '#FFFFFF',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            borderRadius: '25px',
            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)',
            transition: 'all 0.3s ease',
            '&:hover': {
              background: 'linear-gradient(135deg, #003366, #001f33)',
              boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.5)',
              transform: 'scale(1.05)',
            },
          }}
        >
          View All Situational Awareness
        </Button>
      </Box>
    </Container>
  );
};

export default Slider;
