import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Grid, Card, CardContent, CardMedia, Typography, Box, Chip, Stack, Pagination,PaginationItem , IconButton, TextField, Button, Paper, Menu, MenuItem } from '@mui/material';
import { Newspaper as NewspaperIcon, MoreHoriz as MoreHorizIcon } from '@mui/icons-material';
import UserNavbar from './UserNavbar';
import ClearIcon from '@mui/icons-material/Clear'; // Import the icon
import NewsModal from './NewsModal';
import { useNavigate } from 'react-router-dom';
import { FirstPage, LastPage, ArrowBack, ArrowForward } from '@mui/icons-material';

const NewsPage = () => {
  const [newsItems, setNewsItems] = useState([]);
  const [filteredNewsItems, setFilteredNewsItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [keyword, setKeyword] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6);
  const [open, setOpen] = useState(false);
  const [selectedNews, setSelectedNews] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null); // For "More" dropdown
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNewsItems = async () => {
      try {
        const newsResponse = await axios.get('/api/news');
        const sortedNewsItems = newsResponse.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        const uniqueCategories = [...new Set(newsResponse.data.flatMap(item => item.categories.split(',')))];
        setCategories(['All', ...uniqueCategories]);
        setNewsItems(sortedNewsItems);
        setFilteredNewsItems(sortedNewsItems);
      } catch (error) {
        console.error('Error fetching news items:', error);
      }
    };
    fetchNewsItems();
  }, []);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    filterNewsItems(category, keyword, startDate, endDate);
    setCurrentPage(1);
    handleCloseMore(); // Close dropdown if a category is selected
  };

  const handleKeywordChange = (event) => {
    setKeyword(event.target.value);
    filterNewsItems(selectedCategory, event.target.value, startDate, endDate);
    setCurrentPage(1);
  };

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    filterNewsItems(selectedCategory, keyword, start, end);
    setCurrentPage(1);
  };

  const clearFilters = () => {
    setSelectedCategory('All');
    setKeyword('');
    setStartDate('');
    setEndDate('');
    setFilteredNewsItems(newsItems);
    setCurrentPage(1);
  };

  const filterNewsItems = (category, keyword, start, end) => {
    let filteredItems = newsItems;

    if (category !== 'All') {
      filteredItems = filteredItems.filter((newsItem) =>
        newsItem.categories.split(',').includes(category)
      );
    }

    if (keyword) {
      filteredItems = filteredItems.filter((newsItem) =>
        newsItem.description.toLowerCase().includes(keyword.toLowerCase())
      );
    }

    if (start && end) {
      const startDateObj = new Date(start);
      const endDateObj = new Date(end);
      filteredItems = filteredItems.filter((newsItem) => {
        const newsDate = new Date(newsItem.date);
        return newsDate >= startDateObj && newsDate <= endDateObj;
      });
    }

    setFilteredNewsItems(filteredItems);
  };

  const handleNewsClick = (newsItem) => {
    setSelectedNews(newsItem);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedNews(null);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleBackClick = () => {
    navigate('/user');
  };

  const handleOpenMore = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMore = () => {
    setAnchorEl(null);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredNewsItems.slice(indexOfFirstItem, indexOfLastItem);

  const getCategoryStyles = (category) => {
    if (category.includes('National')) {
      return {
        cardBackground: 'linear-gradient(135deg, #004d00, #006600)', // Dark green gradient
        chipBackground: '#006600',
        color: '#FFFFFF',
      };
    }
    if (category.includes('Regional')) {
      return {
        cardBackground: 'linear-gradient(135deg, #004d4d, #008080)',  // Teal gradient
        chipBackground: '#008080',  // Teal background for the chip
        color: '#FFFFFF',  // White text color
      };
    }
    if (category.includes('International')) {
      return {
        cardBackground: 'linear-gradient(135deg, #000000, #434343)', // Black gradient
        chipBackground: '#434343',
        color: '#FFFFFF',
      };
    }
    return {
      cardBackground: 'linear-gradient(135deg, #434343, #000000)', // Default black gradient
      chipBackground: '#434343',
      color: '#FFFFFF',
    };
  };

  const renderMedia = (media) => {
    const mediaType = media.split('.').pop().toLowerCase();
    const isVideo = ['mp4', 'webm', 'ogg'].includes(mediaType);

    if (isVideo) {
      return (
        <video
          height="200"
          controls
          style={{ borderRadius: '16px 16px 0 0', width: '100%' }}
        >
          <source src={`https://smmosaic.com/uploads/${media}`} type={`video/${mediaType}`} />
          Your browser does not support the video tag.
        </video>
      );
    } else {
      return (
        <CardMedia
          component="img"
          height="200"
          image={`https://smmosaic.com/uploads/${media}`}
          alt="news media"
          sx={{
            borderRadius: '16px 16px 0 0',
          }}
        />
      );
    }
  };

  return (
    <>
      <UserNavbar />
      <Container sx={{ marginTop: '5%', pb: '5%', position: 'relative', minHeight: '80vh' }}>
        <Box display="flex" alignItems="center" justifyContent="center" mb={4}>
          <NewspaperIcon sx={{ mr: 2, fontSize: '2rem', color: '#FFD700' }} />
          <Typography variant="h4" component="h2" gutterBottom sx={{ color: '#FFD700', fontWeight: 'bold', letterSpacing: '2px', textTransform: 'uppercase' }}>
            All Developments
          </Typography>
        </Box>

        <Paper elevation={6} sx={{ p: 3, mb: 4, backgroundColor: '#1b1b1b', borderRadius: '16px' }}>
  <Typography variant="h6" gutterBottom sx={{ color: '#FFD700' }}>Filters</Typography>
  <Box display="flex" justifyContent="center" alignItems="center" flexWrap="wrap" gap={3}>
    <Stack direction="row" spacing={2}>
      {categories.slice(0, 3).map((category) => (
        <Chip
          key={category}
          label={category}
          onClick={() => handleCategoryChange(category)}
          sx={{
            backgroundColor: getCategoryStyles(category).chipBackground, // Apply the same background color for all categories
            color: selectedCategory === category
              ? getCategoryStyles(category).color
              : 'White', // Still keep the color distinction for active category
            fontWeight: selectedCategory === category ? 'bold' : 'normal',
            cursor: 'pointer',
            borderRadius: '8px',
            px: 2,
            py: 1,
          }}
        />
      ))}
      {categories.length > 3 && (
        <>
          <Chip
            icon={<MoreHorizIcon />}
            label="More"
            onClick={handleOpenMore}
            sx={{
              backgroundColor: '#333',
              color: '#FFD700',
              fontWeight: 'bold',
              cursor: 'pointer',
              borderRadius: '8px',
              px: 2,
              py: 1,
            }}
          />
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMore}
            PaperProps={{
              style: {
                backgroundColor: '#333',
                color: '#FFD700',
              },
            }}
          >
            {categories.slice(3).map((category) => (
              <MenuItem
                key={category}
                onClick={() => handleCategoryChange(category)}
                sx={{
                  backgroundColor: getCategoryStyles(category).chipBackground, // Apply the background color from getCategoryStyles
                  color: selectedCategory === category
                    ? getCategoryStyles(category).color
                    : '#FFD700',
                  fontWeight: selectedCategory === category ? 'bold' : 'normal',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#444',
                  },
                }}
              >
                {category}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </Stack>
    <TextField
      label="Search by keyword"
      variant="outlined"
      value={keyword}
      onChange={handleKeywordChange}
      sx={{
        width: '300px',
        backgroundColor: '#2b2b2b',
        borderRadius: '8px',
        input: { color: '#FFD700' },
        label: { color: '#FFD700' },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#FFD700',
          },
          '&:hover fieldset': {
            borderColor: '#FFA500',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#FFD700',
          },
        },
      }}
    />
    <TextField
      label="Start Date"
      type="date"
      InputLabelProps={{
        shrink: true,
      }}
      value={startDate}
      onChange={(e) => handleDateChange(e.target.value, endDate)}
      sx={{
        width: '200px',
        backgroundColor: '#2b2b2b',
        borderRadius: '8px',
        input: { color: '#FFD700' },
        label: { color: '#FFD700' },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#FFD700',
          },
          '&:hover fieldset': {
            borderColor: '#FFA500',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#FFD700',
          },
        },
      }}
    />
    <TextField
      label="End Date"
      type="date"
      InputLabelProps={{
        shrink: true,
      }}
      value={endDate}
      onChange={(e) => handleDateChange(startDate, e.target.value)}
      sx={{
        width: '200px',
        backgroundColor: '#2b2b2b',
        borderRadius: '8px',
        input: { color: '#FFD700' },
        label: { color: '#FFD700' },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#FFD700',
          },
          '&:hover fieldset': {
            borderColor: '#FFA500',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#FFD700',
          },
        },
      }}
    />
    <Button
      variant="contained"
      onClick={clearFilters}
      sx={{
        backgroundColor: '#333',
        color: '#FFD700',
        borderRadius: '8px',
        px: 3,
        py: 1,
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        '&:hover': {
          backgroundColor: '#444',
        },
      }}
    >
      <ClearIcon sx={{ color: '#FFD700' }} />
      Clear Filters
    </Button>
  </Box>
</Paper>

{/* Buttons and Pagination Box */}
<Box sx={{ 
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  mt: 4,
  mb: 4, // Add margin-bottom for spacing
}}>
  <IconButton
    onClick={handleBackClick}
    sx={{
      background: 'linear-gradient(135deg, #1b2839, #4e4376, #2b5876, #f37335)',
      color: '#FFD700',
      padding: '10px',
      borderRadius: '50%',
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
      transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
      '&:hover': {
        transform: 'scale(1.1)',
        boxShadow: '0 6px 15px rgba(0, 0, 0, 0.4)',
      }
    }}
  >
    <ArrowBack />
  </IconButton>

  <Pagination
    count={Math.ceil(filteredNewsItems.length / itemsPerPage)}
    page={currentPage}
    onChange={handlePageChange}
    color="primary"
    boundaryCount={2} // Show the first and last two pages
    siblingCount={1} // Show one sibling page on each side of the current page
    renderItem={(item) => {
      let label;
      let icon;

      switch (item.type) {
        case 'first':
          label = 'First';
          icon = <FirstPage sx={{ color: 'white' }} />;
          break;
        case 'previous':
          label = 'Previous';
          icon = <ArrowBack sx={{ color: 'white' }} />;
          break;
        case 'next':
          label = 'Next';
          icon = <ArrowForward sx={{ color: 'white' }} />;
          break;
        case 'last':
          label = 'Last';
          icon = <LastPage sx={{ color: 'white' }} />;
          break;
        default:
          label = item.page; // For number pages
          break;
      }

      return (
        <PaginationItem
          {...item}
          sx={{
            color: theme => theme.palette.text.primary,
            display: 'flex',
            alignItems: 'center',
            '&.MuiPaginationItem-root': {
              color: 'white', // Set icon and text color to white
            },
          }}
          label={
            <>
              {icon}
              <span style={{ marginLeft: '4px', color: 'white' }}>{label}</span>
            </>
          }
        />
      );
    }}
  />

</Box>


        <Grid container spacing={4}>
          {currentItems.map((newsItem) => (
            <Grid item xs={12} sm={6} md={4} key={newsItem.id}>
              <Card
                onClick={() => handleNewsClick(newsItem)}
                sx={{
                  cursor: 'pointer',
                  transition: 'transform 0.3s ease-in-out',
                  background: getCategoryStyles(newsItem.categories).cardBackground,
                  color: getCategoryStyles(newsItem.categories).color,
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: '0 4px 20px rgba(0,0,0,0.5)',
                  },
                  borderRadius: '16px',
                  height: '350px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box sx={{ position: 'relative', flex: '1 0 auto' }}>
                  {renderMedia(newsItem.media.split(',')[0])}
                  <Chip
                    label={newsItem.categories}
                    sx={{
                      position: 'absolute',
                      top: '10px',
                      right: '10px',
                      backgroundColor: getCategoryStyles(newsItem.categories).chipBackground,
                      color: getCategoryStyles(newsItem.categories).color,
                      fontWeight: 'bold',
                    }}
                  />
                </Box>
                <CardContent sx={{ flex: '0 1 auto' }}>
                  <Typography variant="body2" color="textSecondary" sx={{ color: 'inherit' }}>
                    {newsItem.description.length > 80 ? `${newsItem.description.substring(0, 80)}...` : newsItem.description}
                  </Typography>
                  <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '8px',
        fontSize: '16px',
        fontWeight: 'bold',
      }}
    >
      {/* Credibility Chip on the left */}
      <Chip
        label={newsItem.credibility}
        sx={{
          backgroundColor:
            newsItem.credibility === 'High'
              ? '#800000' // Dark maroon for "High"
              : newsItem.credibility === 'Medium'
              ? '#FF8C00' // Dark orange for "Medium"
              : '#006400', // Dark green for "Low",
          color: 'white',
          fontWeight: 'bold',
          borderRadius: '12px',
          padding: '6px 12px',
          fontSize: '0.85rem',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
        }}
      />

      {/* Date on the right side */}
      <Typography sx={{ color: 'white', textAlign: 'right' }}>
        {new Date(newsItem.date).toLocaleDateString()}
      </Typography>
    </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

       

        <NewsModal open={open} handleClose={handleClose} newsItem={selectedNews} />
      </Container>
    </>
  );
};

export default NewsPage;
