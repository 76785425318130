import React, { useState } from 'react';
import {
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  CircularProgress,
  Fade,
  Autocomplete,
} from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SaveIcon from '@mui/icons-material/Save';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import PeopleIcon from '@mui/icons-material/People';

const FormContainer = styled(Paper)({
  padding: '32px',
  marginTop: '32px',
  backgroundColor: '#f5f5f5',
  borderRadius: '15px',
  boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s ease-in-out',
  color: '#333',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 15px 30px rgba(0, 0, 0, 0.2)',
  },
  '& .MuiTextField-root': {
    margin: '8px',
    backgroundColor: '#fff',
    borderRadius: '5px',
  },
  '& .MuiInputLabel-root': {
    color: '#333',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#ccc',
    },
    '&:hover fieldset': {
      borderColor: '#888',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#333',
    },
  },
  '& .MuiButton-root': {
    margin: '24px 8px 8px',
  },
});

const StyledButton = styled(Button)({
  color: 'white',
  '&:hover': {
    backgroundImage: 'linear-gradient(315deg, #ff9800, #ff5722)',
  },
  '&.Mui-disabled': {
    background: 'rgba(255, 152, 0, 0.5)',
  },
});

const roles = ['admin', 'user'];

const AddUserForm = () => {
  const [user, setUser] = useState({
    username: '',
    email: '',
    password: '',
    role: '',
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleRoleChange = (event, newValue) => {
    setUser({
      ...user,
      role: newValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      // Ensure the correct structure is being sent
      await axios.post('/api/adduser/add', {
        username: user.username,
        email: user.email,
        password: user.password,
        role: user.role,
      });
  
      toast.success('User added successfully!');
      setUser({
        username: '',
        email: '',
        password: '',
        role: '',
      });
      setSuccess(true);
    } catch (error) {
      toast.error('Error adding user.');
      console.error(error);  // Log the error for debugging
    } finally {
      setLoading(false);
      setTimeout(() => setSuccess(false), 3000);
    }
  };
  

  return (
    <FormContainer>
      <ToastContainer />
      <Typography variant="h4" gutterBottom color="#333" textAlign="center">
        <PeopleIcon /> Add User
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Username"
              fullWidth
              name="username"
              value={user.username}
              onChange={handleInputChange}
              required
              InputProps={{
                startAdornment: <TextFieldsIcon color="action" />,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email"
              fullWidth
              name="email"
              value={user.email}
              onChange={handleInputChange}
              required
              type="email"
              InputProps={{
                startAdornment: <EmailIcon color="action" />,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Password"
              fullWidth
              name="password"
              value={user.password}
              onChange={handleInputChange}
              required
              type="password"
              InputProps={{
                startAdornment: <LockIcon color="action" />,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={roles}
              value={user.role}
              onChange={handleRoleChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Role"
                  required
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: <PeopleIcon color="action" />,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledButton
              type="submit"
              variant="contained"
              disabled={loading}
              fullWidth
              endIcon={loading ? <CircularProgress size={24} /> : <SaveIcon />}
            >
              {loading ? 'Submitting...' : 'Submit'}
            </StyledButton>
          </Grid>
        </Grid>
      </form>
      {success && (
        <Fade in={success}>
          <Typography variant="h6" sx={{ color: '#388e3c', mt: 2 }}>
            User added successfully!
          </Typography>
        </Fade>
      )}
    </FormContainer>
  );
};

export default AddUserForm;
