import React from 'react';
import { Box, Typography, Modal, Backdrop, Fade, CardMedia, IconButton, Link as MuiLink } from '@mui/material';
import { format } from 'date-fns';
import CloseIcon from '@mui/icons-material/Close';

const NewsModal = ({ open, handleClose, newsItem }) => {

  const getCategoryStyles = (category) => {
    if (category.includes('National')) {
      return {
        modalBackground: 'linear-gradient(135deg, #004d00, #006600)', // Dark green gradient for National
        color: '#FFFFFF', // White text color
      };
    }
    if (category.includes('Regional')) {
      return {
        modalBackground: 'linear-gradient(135deg, #004d4d, #008080)', // Dark blue gradient for Regional
        color: '#FFFFFF', // White text color
      };
    }
    if (category.includes('International')) {
      return {
        modalBackground: 'linear-gradient(135deg, #000000, #434343)', // Black gradient for International
        color: '#FFFFFF', // White text color
      };
    }
    return {
      modalBackground: 'linear-gradient(135deg, #000000, #434343)', // Default gold to orange gradient
      color: '#FFFFFF', // Dark text color
    };
  };

  const styles = newsItem ? getCategoryStyles(newsItem.categories) : {};

  const renderMedia = (media) => {
    const mediaType = media.split('.').pop().toLowerCase();
    const isVideo = ['mp4', 'webm', 'ogg'].includes(mediaType);
  
    if (isVideo) {
      return (
        <video
          height="400"
          controls
          autoPlay
          style={{ borderRadius: '20px', width: '100%', marginBottom: '1.5rem' }}
        >
          <source src={`https://smmosaic.com/uploads/${media}`} type={`video/${mediaType}`} />
          Your browser does not support the video tag.
        </video>
      );
    } else {
      return (
        <CardMedia
          component="img"
          height="400"
          image={`https://smmosaic.com/uploads/${media}`}
          alt={newsItem.title}
          sx={{
            borderRadius: '20px',
            mb: 3,
            mt: 2,
            objectFit: 'fill',
            transition: 'transform 0.5s ease',
            width: '50%', // Set the width to 50%
            margin: '0 auto', // Align the image to the center
          }}
        />
      );
    }
  };


 const renderDescriptionWithLinks = (description) => {
  const urlPattern = /(https?:\/\/[^\s]+)/g;
  const parts = description.split(urlPattern);

  return parts.map((part, index) =>
    urlPattern.test(part) ? (
      <MuiLink
        href={part}
        key={index}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          display: 'block',   // Ensures the link always starts on a new line
          color: '#FFD700',   // Bright gold color to make the link stand out
          fontWeight: 'bold', // Make the link bold
          textDecoration: 'underline', // Underline for visibility
          transition: 'color 0.3s ease, transform 0.2s ease', // Smooth hover effects
          '&:hover': {
            color: '#FFA500', // Lighter orange on hover
            transform: 'scale(1.05)', // Slightly increase size on hover
          },
        }}
      >
        {part}
      </MuiLink>
    ) : (
      <Typography key={index} variant="body1" component="span" sx={{ display: 'block' }}>
        {part}
      </Typography>
    )
  );
};
  
  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '1000px',  // Increased width
            maxWidth: '95%',
            maxHeight: '90vh',  // Ensure the modal does not exceed the viewport height
            borderRadius: '20px',
            boxShadow: 24,
            p: 4,
            outline: 'none',
            overflowY: 'auto',  // Enable vertical scrolling
            background: styles.modalBackground || 'linear-gradient(135deg, #ffffff 0%, #f5f5f5 50%, #e0e0e0 100%)',
            color: styles.color || '#333',
            scrollbarWidth: 'thin',  // For Firefox
            '&::-webkit-scrollbar': {
              width: '12px',  // Wider for better aesthetics
            },
            '&::-webkit-scrollbar-track': {
              background: '#333', // Darker track for contrast
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
              borderRadius: '10px',
              border: '3px solid #333', // Border matching the track
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#555',
            },
          }}
        >
          {/* Close button */}
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 16,
              right: 16,
              color: styles.color,  // Match close button color with modal text color
              transition: 'color 0.3s ease',
              '&:hover': {
                color: '#ff0000',  // Darker shade on hover
              },
            }}
          >
            <CloseIcon />
          </IconButton>

          {newsItem && (
            <>
              {renderMedia(newsItem.media.split(',')[0])}
              <Typography
                gutterBottom
                variant="h4"
                component="div"
                sx={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  color: styles.color,
                }}
              >
                {newsItem.title}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  textAlign: 'center',
                  mb: 3,
                  fontSize: '1.1rem',
                  lineHeight: 1.6,
                  color: styles.color,
                }}
              >
                {renderDescriptionWithLinks(newsItem.description)}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  color: styles.color,
                }}
              >
                {format(new Date(newsItem.date), 'dd MMMM, yyyy')}
              </Typography>
            </>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

export default NewsModal;
