import React from 'react';
import { styled } from '@mui/system';

const TickerContainer = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  position: 'relative',
  height: '50px',
  display: 'flex',
  alignItems: 'center',
  //justifyContent: 'center',
  flex: 1,
  borderRadius: '10px',
  marginLeft:'50px'
}));

const TickerText = styled('div')(({ theme }) => ({
  display: 'inline-block',
  fontSize: '1.5rem',
  fontFamily: '"Roboto Condensed", sans-serif',
  fontWeight: 'bold',
  letterSpacing: '0.1em',
  color: '#50C878', // Change this to your preferred color
  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
  animation: 'blink 3.5s ease-in-out infinite',
  '@keyframes blink': {
    '0%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
}));

const Ticker = () => {
  return (
    <TickerContainer>
      <TickerText>Daily Social Media and OSINT Mosaic</TickerText>
    </TickerContainer>
  );
};

export default Ticker;
