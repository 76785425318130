import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './AnnouncementStrip.css';

const AnnouncementStrip = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get('/api/article');
        const sortedArticles = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setArticles(sortedArticles.slice(0, 5)); // Select the first 5 articles
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };

    fetchArticles();
  }, []);

  return (
    <div className="announcement-strip">
      <div className="announcement-content">
        {articles.map((article, index) => (
          <span key={index} className="announcement-item">
            {`${article.title} (${new Date(article.date).toLocaleDateString()})`}
          </span>
        ))}
      </div>
    </div>
  );
};

export default AnnouncementStrip;
