import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Box, Menu, MenuItem, IconButton, Avatar, ListItemIcon, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { Logout, Person, Home, Menu as MenuIcon, Article, Newspaper, TrendingUp } from '@mui/icons-material'; // Importing appropriate icons
import { styled } from '@mui/system';
import axios from 'axios';
import Ticker from './Ticker'; // Import the Ticker component

const CustomButton = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'inline-block',
  margin: '0 10px',
  '& a': {
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    padding: '6px 12px',
    fontFamily: '"Oswald", sans-serif', // Using 'Oswald' for a clean, bold look
    fontSize: '1.2rem', // Adjusting font size for readability
    letterSpacing: '0.05em', // Adding some letter spacing to enhance the movie credit feel
  },
  '&:hover::before': {
    width: '100%',
    transition: 'width 0.3s',
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: '3px',
    width: '0',
    backgroundColor: '#65ccb7', // Accent color for visual consistency
    transition: 'width 0.3s',
  },
  '&.active::before': {
    width: '100%',
  },
}));


const UserNavbar = ({ scrollToSection, sliderRef, newsSliderRef, trendsSliderRef }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthStatus = async () => {
      const token = localStorage.getItem('authToken');
  
      if (token) {
        try {
          const response = await axios.get('/api/user/check-token', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
  
          if (response.status === 200) {
            setIsAuthenticated(true);
          } else {
            setIsAuthenticated(false);
            localStorage.removeItem('authToken');
            navigate('/');
          }
        } catch (error) {
          console.error('Error verifying token:', error.response?.data || error.message);
          setIsAuthenticated(false);
          localStorage.removeItem('authToken');
          navigate('/');
        }
      } else {
        navigate('/');
      }
    };
  
    checkAuthStatus();
  }, [navigate]);
  
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem('authToken');
      await axios.post('/api/user/logout', {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      localStorage.removeItem('authToken');
      setIsAuthenticated(false);
  
      navigate('/');
      handleMenuClose();
      console.log('Logged out');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <AppBar position="fixed" sx={{ 
      background: 'linear-gradient(135deg, #0d1321 0%, #232a48 50%, #1b2839 100%)',
        backgroundSize: '300% 300%', 
        animation: 'gradientAnimation 10s ease infinite', 
        zIndex: 1300 
      }}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleDrawerToggle}
          sx={{ display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>

        <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
          <List>
          <ListItem button onClick={() => { scrollToSection(newsSliderRef); handleDrawerToggle(); }}>
              <ListItemIcon><Newspaper /></ListItemIcon> {/* Changed to Newspaper icon for News */}
              <ListItemText primary="News" />
            </ListItem>
            <ListItem button onClick={() => { scrollToSection(trendsSliderRef); handleDrawerToggle(); }}>
              <ListItemIcon><TrendingUp /></ListItemIcon> {/* Changed to TrendingUp icon for Trends */}
              <ListItemText primary="Trends" />
            </ListItem>
            <ListItem button onClick={() => { scrollToSection(sliderRef); handleDrawerToggle(); }}>
              <ListItemIcon><Home /></ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
          
          </List>
        </Drawer>

        <Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center' }}>
        <CustomButton>
            <Link to="#" onClick={() => scrollToSection(newsSliderRef)}>
              <Newspaper sx={{ mr: 1 }} /> {/* Changed to Newspaper icon for News */}
              Developments
            </Link>
          </CustomButton>
          <CustomButton>
            <Link to="#" onClick={() => scrollToSection(trendsSliderRef)}>
              <TrendingUp sx={{ mr: 1 }} /> {/* Changed to TrendingUp icon for Trends */}
              Trends
            </Link>
          </CustomButton>
          <CustomButton>
            <Link to="#" onClick={() => scrollToSection(sliderRef)}>
              <Article sx={{ mr: 1 }} /> {/* Kept the Article icon for Articles */}
              Situational Awareness
            </Link>
          </CustomButton>
      
        </Box>
        <Ticker />
        {isAuthenticated && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenuOpen}
              color="inherit"
            >
              <Avatar alt="Profile Picture" src="/static/images/avatar/1.jpg" />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

// Add the keyframes animation at the bottom of the file or in a separate CSS file
const styles = `
@keyframes gradientAnimation {
   0% { background-position: 100% 0%; }
  50% { background-position: 0% 100%; }
  100% { background-position: 100% 0%; }
}
`;

export default UserNavbar;

// Inject the styles into the document head
if (typeof document !== 'undefined') {
  const styleSheet = document.createElement('style');
  styleSheet.type = 'text/css';
  styleSheet.innerText = styles;
  document.head.appendChild(styleSheet);
}
