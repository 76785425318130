import React, { useEffect, useState, useCallback } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Snackbar,
  Typography,
  Alert,
  Pagination,
  Grid,
  Button,
  InputAdornment,
} from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PeopleIcon from '@mui/icons-material/People';
import SearchIcon from '@mui/icons-material/Search';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const StyledTableContainer = styled(TableContainer)({
  marginTop: '32px',
});

const FilterContainer = styled(Grid)({
  marginTop: '20px',
  padding: '20px',
  borderRadius: '15px',
  backgroundColor: '#f9f9f9',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
});

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '20px', // Rounded edges for sleek look
    backgroundColor: '#ffffff',
    boxShadow: '0 3px 5px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease', // Smooth transitions
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
    '&.Mui-focused': {
      backgroundColor: '#f9f9f9',
      borderColor: '#6200ea',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#6200ea',
  },
  '& .Mui-focused .MuiInputLabel-root': {
    color: '#6200ea',
  },
  '& .MuiOutlinedInput-input': {
    padding: '12px 14px',
  },
}));

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [editUser, setEditUser] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [page, setPage] = useState(1); // Pagination
  const [totalPages, setTotalPages] = useState(1); // Total pages for pagination
  const [filters, setFilters] = useState({
    username: '',
    email: '',
    role: '',
  });

  // Fetch all users with pagination and filtering, memoized with useCallback
  const fetchUsers = useCallback(async () => {
    try {
      const response = await axios.get(`/api/adduser`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        params: {
          page, // current page
          username: filters.username,
          email: filters.email,
          role: filters.role,
        },
      });
      setUsers(response.data.users);
      setTotalPages(response.data.totalPages); // total pages based on the backend response
    } catch (error) {
      console.error('Error fetching users', error);
      setSnackbar({ open: true, message: 'Error fetching users', severity: 'error' });
    }
  }, [page, filters]); // Dependencies include page and filters

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]); // Now fetchUsers is included in the dependency array without triggering ESLint warnings

  // Handle Edit Button Click
  const handleEditClick = (user) => {
    setEditUser(user);
    setOpenEditDialog(true);
  };

  // Handle Delete Button Click
  const handleDeleteClick = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await axios.delete(`/api/adduser/delete/${userId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setSnackbar({ open: true, message: 'User deleted successfully', severity: 'success' });
        fetchUsers(); // Refresh the user list
      } catch (error) {
        console.error('Error deleting user', error);
        setSnackbar({ open: true, message: 'Error deleting user', severity: 'error' });
      }
    }
  };

  // Handle Edit Dialog Close
  const handleEditDialogClose = () => {
    setOpenEditDialog(false);
    setEditUser(null);
  };

  // Handle Input Change in Edit Dialog
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditUser({
      ...editUser,
      [name]: value,
    });
  };

  // Handle Save Changes in Edit Dialog
  const handleSaveChanges = async () => {
    try {
      await axios.put(`/api/adduser/update/${editUser.id}`, editUser, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setSnackbar({ open: true, message: 'User updated successfully', severity: 'success' });
      handleEditDialogClose();
      fetchUsers(); // Refresh the user list
    } catch (error) {
      console.error('Error updating user', error);
      setSnackbar({ open: true, message: 'Error updating user', severity: 'error' });
    }
  };

  // Handle Snackbar Close
  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  // Handle Pagination Change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Handle Filter Input Change
  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <Typography variant="h4" align="center" gutterBottom sx={{ color: 'yellow' }}>
        <PeopleIcon /> User Management
      </Typography>

      {/* Filters Section */}
      <FilterContainer container spacing={3} alignItems="center">
        <Grid item xs={12} sm={4}>
          <StyledTextField
            label="Username"
            name="username"
            value={filters.username}
            onChange={handleFilterChange}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircleIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <StyledTextField
            label="Email"
            name="email"
            value={filters.email}
            onChange={handleFilterChange}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailOutlineIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <StyledTextField
            label="Role"
            name="role"
            value={filters.role}
            onChange={handleFilterChange}
            fullWidth
            select
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            SelectProps={{ native: true }}
          >
            <option value="">All</option>
            <option value="admin">Admin</option>
            <option value="user">User</option>
          </StyledTextField>
        </Grid>
      </FilterContainer>

      <StyledTableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>ID</strong></TableCell>
              <TableCell><strong>Username</strong></TableCell>
              <TableCell><strong>Email</strong></TableCell>
              <TableCell><strong>Role</strong></TableCell>
              <TableCell align="center"><strong>Actions</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.length > 0 ? (
              users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.id}</TableCell>
                  <TableCell>{user.username}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.role}</TableCell>
                  <TableCell align="center">
                    <Tooltip title="Edit User">
                      <IconButton color="primary" onClick={() => handleEditClick(user)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete User">
                      <IconButton color="secondary" onClick={() => handleDeleteClick(user.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No users found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </StyledTableContainer>

    {/* Pagination */}
<Pagination
  count={totalPages}
  page={page}
  onChange={handlePageChange}
  sx={{
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    '& .MuiPaginationItem-root': {
      color: '#fff', // White text color for the page numbers
      fontWeight: 'bold',
    },
    '& .Mui-selected': {
      backgroundColor: '#ffffff33', // Light white background for selected item
      color: '#fff', // White text for selected item
      fontWeight: 'bold',
    },
    '& .MuiPaginationItem-ellipsis': {
      color: '#fff', // White color for ellipsis
    },
    '& .MuiPaginationItem-icon': {
      color: '#fff', // White color for next/previous icons
    },
  }}
/>


      {/* Edit User Dialog */}
      <Dialog open={openEditDialog} onClose={handleEditDialogClose}>
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          {editUser && (
            <>
              <TextField
                margin="dense"
                label="Username"
                name="username"
                value={editUser.username}
                onChange={handleInputChange}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                label="Email"
                name="email"
                type="email"
                value={editUser.email}
                onChange={handleInputChange}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                label="Password"
                name="password"
                type="password"
                onChange={handleInputChange}
                fullWidth
                placeholder="Leave blank to keep the current password"
              />
              <TextField
                margin="dense"
                label="Role"
                name="role"
                value={editUser.role}
                onChange={handleInputChange}
                fullWidth
                required
                select
                SelectProps={{ native: true }}
              >
                <option value="admin">Admin</option>
                <option value="user">User</option>
              </TextField>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveChanges} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default UserManagement;
