import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Box, CssBaseline, Container, useTheme, useMediaQuery } from '@mui/material';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import ArticleForm from './ArticleForm';
import ArticleList from './ArticleList';
import AddNewsForm from './AddNewsForm';
import AddTrendForm from './AddTrendForm';
import AddUserForm from './AddUserForm';
import NewsList from './NewsList';  
import TrendList from './TrendList';  
import UserManagement from './UserManagement'; 

const AdminDashboard = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg')); // Use 'lg' breakpoint for larger screens

  const [sidebarOpen, setSidebarOpen] = useState(false); // Control drawer open state

  const handleDrawerToggle = () => {
    setSidebarOpen(!sidebarOpen); // Correctly toggles the state
  };
  

  return (
    <Box>
      <CssBaseline />
      <Navbar handleDrawerToggle={handleDrawerToggle} /> {/* Pass drawer toggle function to Navbar */}
      <Box sx={{ display: 'flex', width: '100%', minHeight: '70vh' }}>
        <Sidebar open={sidebarOpen} handleDrawerToggle={handleDrawerToggle} isMobile={!matches} /> {/* Pass open state and toggle to Sidebar */}
        <Box component="main" sx={{
          flexGrow: 1,
          p: 3,
          minHeight: '70vh',
          paddingTop: matches ? '64px' : '56px',  // Adjust padding top based on navbar height
        }}>
          <Container maxWidth="xl">
            <Routes>
              <Route path="add-article" element={<ArticleForm />} />
              <Route path="articles" element={<ArticleList />} />
              <Route path="add-news" element={<AddNewsForm />} />  
              <Route path="news-list" element={<NewsList />} />  
              <Route path="add-trend" element={<AddTrendForm />} />  
              <Route path="trend-list" element={<TrendList />} />  
              <Route path="add-user" element={<AddUserForm />} />  
              <Route path="manage-user" element={<UserManagement />} /> 
            </Routes>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default AdminDashboard;
