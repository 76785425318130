import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, Avatar, useTheme, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import AccountCircle from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import axios from 'axios';  
import Logo from '../../assets/logoblue.png';  

const Navbar = ({ handleDrawerToggle }) => {  // Accept handleDrawerToggle as a prop
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);  
  const open = Boolean(anchorEl);
  const [iconHover, setIconHover] = useState(false);

  useEffect(() => {
    const checkAuthStatus = async () => {
      const token = localStorage.getItem('authToken');

      if (token) {
        try {
          const response = await axios.get('/api/user/check-token', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.status === 200) {
            setIsAuthenticated(true);
          } else {
            setIsAuthenticated(false);
            localStorage.removeItem('authToken');
            navigate('/');
          }
        } catch (error) {
          console.error('Error verifying token:', error);
          setIsAuthenticated(false);
          localStorage.removeItem('authToken');
          navigate('/');
        }
      } else {
        navigate('/');
      }
    };

    checkAuthStatus();
  }, [navigate]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    handleClose();
    try {
      const token = localStorage.getItem('authToken');
      await axios.post('/api/user/logout', {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      localStorage.removeItem('authToken');
      setIsAuthenticated(false);
      navigate('/');
    } catch (error) {
      console.error('Error during logout:', error.response || error.message);
    }
  };

  return (
    <AppBar position="fixed" sx={{
      background: 'linear-gradient(135deg, #0d1321 0%, #3f4c6b 50%, #0d1321 100%)',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      width: '100%',
      zIndex: theme.zIndex.drawer + 1,
      transition: 'box-shadow 0.3s ease-in-out',
    }}>
      <Toolbar sx={{
        minHeight: isMobile ? '56px' : '64px',
        justifyContent: 'space-between',
        padding: '0 20px',
        animation: 'fadeIn 0.5s',
      }}>
       
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar src={Logo} sx={{ width: 30, height: 30, marginRight: 1 }} />
          <Typography variant={isMobile ? 'h6' : 'h5'} sx={{
            flexGrow: 1,
            textAlign: 'center',
            letterSpacing: 1,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            marginLeft: 1,
          }}>
            Social Media Mosaic
          </Typography>
        </div>
        {isAuthenticated && (
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            sx={{ transform: iconHover ? 'scale(1.1)' : 'scale(1)', transition: 'transform 0.3s' }}
            onMouseEnter={() => setIconHover(true)}
            onMouseLeave={() => setIconHover(false)}
          >
            <AccountCircle />
          </IconButton>
        )}
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={handleClose}
          TransitionProps={{
            timeout: 350,
          }}
        >
          <MenuItem onClick={handleClose}>
            <PersonIcon sx={{ mr: 1 }} />
            Profile
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <LogoutIcon sx={{ mr: 1 }} />
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
