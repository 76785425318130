import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Grid, Card, CardContent, CardMedia, Typography, Pagination,PaginationItem, Box, useTheme, CardActionArea, Chip, Stack, IconButton, TextField, Button, Paper } from '@mui/material';
import { Article as ArticleIcon } from '@mui/icons-material';  
import UserNavbar from './UserNavbar';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import { format } from 'date-fns';
import { FirstPage, LastPage, ArrowBack, ArrowForward } from '@mui/icons-material';

const ArticlesPage = () => {
  const theme = useTheme();
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 6;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get('/api/article')
      .then(response => {
        // Sort articles by date first, then by 'id' or 'uploadTime' if the dates are the same
        const sortedArticles = response.data.sort((a, b) => {
          const dateComparison = new Date(b.date) - new Date(a.date);
          if (dateComparison === 0) {
            // Secondary sort by 'id' or 'uploadTime' if the dates are the same
            return b.id - a.id; // or b.uploadTime - a.uploadTime if you have a timestamp
          }
          return dateComparison;
        });
        
        setArticles(sortedArticles);
        setFilteredArticles(sortedArticles);
        setLoading(false);
      })
      .catch(error => console.error('Error fetching articles:', error));
  }, []);
  
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleArticleClick = (articleId) => {
    navigate(`/article/${articleId}`);
  };

  const handleBackClick = () => {
    navigate('/user');
  };

  const handleKeywordChange = (event) => {
    setKeyword(event.target.value);
    filterArticles(event.target.value, startDate, endDate);
    setCurrentPage(1);
  };

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    filterArticles(keyword, start, end);
    setCurrentPage(1);
  };

  const clearFilters = () => {
    setKeyword('');
    setStartDate('');
    setEndDate('');
    setFilteredArticles(articles);
    setCurrentPage(1);
  };

  const filterArticles = (keyword, start, end) => {
    let filteredItems = articles;

    if (keyword) {
      filteredItems = filteredItems.filter((article) =>
        article.description.toLowerCase().includes(keyword.toLowerCase()) ||
        article.title.toLowerCase().includes(keyword.toLowerCase())
      );
    }

    if (start && end) {
      const startDateObj = new Date(start);
      const endDateObj = new Date(end);
      filteredItems = filteredItems.filter((article) => {
        const articleDate = new Date(article.date);
        return articleDate >= startDateObj && articleDate <= endDateObj;
      });
    }

    setFilteredArticles(filteredItems);
  };

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = filteredArticles.slice(indexOfFirstArticle, indexOfLastArticle);

  return (
    <>
      <UserNavbar />
      <Container sx={{ marginTop: '5%', pb: '5%', position: 'relative', minHeight: '80vh' }}>
        <Box display="flex" alignItems="center" justifyContent="center" mb={4}>
          <ArticleIcon sx={{ mr: 2, fontSize: '2rem', color: '#FFD700' }} />
          <Typography variant="h4" component="h2" gutterBottom sx={{ color: '#FFD700', fontWeight: 'bold', letterSpacing: '2px', textTransform: 'uppercase' }}>
            All Situational Awareness
          </Typography>
        </Box>

        <Paper elevation={6} sx={{ p: 3, mb: 4, backgroundColor: '#1b1b1b', borderRadius: '16px' }}>
          <Typography variant="h6" gutterBottom sx={{ color: '#FFD700' }}>Filters</Typography>
          <Box display="flex" justifyContent="center" alignItems="center" flexWrap="wrap" gap={3}>
            <TextField
              label="Search by keyword"
              variant="outlined"
              value={keyword}
              onChange={handleKeywordChange}
              sx={{
                width: '300px',
                backgroundColor: '#2b2b2b',
                borderRadius: '8px',
                input: { color: '#FFD700' },
                label: { color: '#FFD700' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#FFD700',
                  },
                  '&:hover fieldset': {
                    borderColor: '#FFA500',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#FFD700',
                  },
                },
              }}
            />
            <TextField
              label="Start Date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={startDate}
              onChange={(e) => handleDateChange(e.target.value, endDate)}
              sx={{
                width: '200px',
                backgroundColor: '#2b2b2b',
                borderRadius: '8px',
                input: { color: '#FFD700' },
                label: { color: '#FFD700' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#FFD700',
                  },
                  '&:hover fieldset': {
                    borderColor: '#FFA500',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#FFD700',
                  },
                },
              }}
            />
            <TextField
              label="End Date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={endDate}
              onChange={(e) => handleDateChange(startDate, e.target.value)}
              sx={{
                width: '200px',
                backgroundColor: '#2b2b2b',
                borderRadius: '8px',
                input: { color: '#FFD700' },
                label: { color: '#FFD700' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#FFD700',
                  },
                  '&:hover fieldset': {
                    borderColor: '#FFA500',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#FFD700',
                  },
                },
              }}
            />
            <Button
              variant="contained"
              onClick={clearFilters}
              sx={{
                backgroundColor: '#333',
                color: '#FFD700',
                borderRadius: '8px',
                px: 3,
                py: 1,
                '&:hover': {
                  backgroundColor: '#444',
                },
              }}
            >
              Clear Filters
            </Button>
          </Box>
        </Paper>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 4,
          mb: 4,
        }}>
          <IconButton
            onClick={handleBackClick}
            sx={{
              background: 'linear-gradient(135deg, #1b2839, #4e4376, #2b5876, #f37335)',
              color: '#FFD700',
              padding: '6px',
              borderRadius: '50%',
              boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
              transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
              '&:hover': {
                transform: 'scale(1.1)',
                boxShadow: '0 6px 15px rgba(0, 0, 0, 0.4)',
              }
            }}
          >
            <ArrowBack />
          </IconButton>
          <Pagination
  count={Math.ceil(filteredArticles.length / articlesPerPage)}
  page={currentPage}
  onChange={handlePageChange}
  color="primary"
  renderItem={(item) => {
    let label;
    let icon;

    switch (item.type) {
      case 'first':
        label = 'First';
        icon = <FirstPage sx={{ color: 'white' }} />;
        break;
      case 'previous':
        label = 'Previous';
        icon = <ArrowBack sx={{ color: 'white' }} />;
        break;
      case 'next':
        label = 'Next';
        icon = <ArrowForward sx={{ color: 'white' }} />;
        break;
      case 'last':
        label = 'Last';
        icon = <LastPage sx={{ color: 'white' }} />;
        break;
      default:
        label = item.page; // For number pages
        break;
    }

    return (
      <PaginationItem
        {...item}
        sx={{
          color: theme => theme.palette.text.primary,
          display: 'flex',
          alignItems: 'center',
          '&.MuiPaginationItem-root': {
            color: 'white', // Set icon and text color to white
          },
        }}
        label={
          <>
            {icon}
            <span style={{ marginLeft: '4px', color: 'white' }}>{label}</span>
          </>
        }
      />
    );
  }}
/>
        </Box>
        <Grid container spacing={4}>
          {loading ? (
            Array.from(new Array(articlesPerPage)).map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Skeleton variant="rectangular" height={250} animation="wave" />
              </Grid>
            ))
          ) : (
            currentArticles.map((article) => (
              <Grid item xs={12} sm={6} md={4} key={article.id}>
                <CardActionArea onClick={() => handleArticleClick(article.id)}>
                  <Card sx={{
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                    borderRadius: '12px',
                    '&:hover': {
                      transform: 'translateY(-5px)',
                      boxShadow: theme.shadows[5],
                    }
                  }}>
                    <CardMedia
                      component="img"
                      height="200"
                      image={`/${article.image}`}
                      alt={article.title}
                      sx={{
                        objectFit: 'fill',
                        borderRadius: '12px 12px 0 0',
                        transition: 'transform 0.5s ease-in-out',
                        '&:hover': {
                          transform: 'scale(1.05)',
                        }
                      }}
                    />
                    <CardContent sx={{ paddingBottom: '16px' }}>
                      <Typography gutterBottom variant="h6" component="div" sx={{ fontWeight: 'bold', color: '#000000' }}>
                        {article.title}
                      </Typography>
                      <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
                     
                      <Typography variant="body2" color="#333333">
                        {article.author} - {format(new Date(article.date), 'dd MMMM yyyy')}
                      </Typography>

                      </Stack>
                      <Typography variant="body2" sx={{ height: 60, overflow: 'hidden', textOverflow: 'ellipsis', color: '#555555' }}>
                        {article.description}
                      </Typography>
                      <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
                        {article.tags && article.tags.map((tag, index) => (
                          <Chip key={`${tag}-${index}`} label={tag} size="small" sx={{ bgcolor: theme.palette.primary.light, color: theme.palette.primary.contrastText }} />
                        ))}
                      </Stack>
                    </CardContent>
                  </Card>
                </CardActionArea>
              </Grid>
            ))
          )}
        </Grid>
       
      </Container>
    </>
  );
};

export default ArticlesPage;
