import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Grid,
  Typography,
  CircularProgress,
  Chip,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

const FormContainer = styled('form')({
  padding: '32px',
  backgroundColor: '#f5f5f5',
  borderRadius: '15px',
  boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
});

const EditTrendForm = ({ open, onClose, trendId }) => {
   
  const [trend, setTrend] = useState({
    name: '',
    media: null,
    mediaName: '',
    category: [],
  });
  const [availableCategories, setAvailableCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch trend details by ID
    const fetchTrendDetails = async () => {
      try {
        const response = await axios.get(`/api/trend/${trendId}`);
        const trendData = response.data;
        setTrend({
          name: trendData.name,
          media: null, // Leave media null initially, unless you handle pre-filled media
          mediaName: trendData.media || '', // Display existing media name, if any
          category: trendData.categories.split(','),
        });
      } catch (error) {
        toast.error('Error fetching trend details.');
      }
    };

    if (trendId) {
      fetchTrendDetails();
    }

    // Fetch available categories
    const fetchCategories = async () => {
      try {
        const response = await axios.get('/api/trend-categories');
        setAvailableCategories(response.data);
      } catch (error) {
        toast.error('Error fetching categories.');
      }
    };

    fetchCategories();
  }, [trendId]);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    if (files && files[0]) {
      setTrend({
        ...trend,
        media: files,
        mediaName: Array.from(files).map((file) => file.name).join(', '), // Update mediaName with selected file(s)
      });
    } else {
      setTrend({
        ...trend,
        [name]: value,
      });
    }
  };

  const handleCategoryChange = (event, newValue) => {
    setTrend({
      ...trend,
      category: newValue.map((option) => (typeof option === 'string' ? option : option.name)),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append('name', trend.name);
    if (trend.media) {
      Array.from(trend.media).forEach((file) => {
        formData.append('media', file);
      });
    }
    formData.append('categories', trend.category.join(','));

    try {
      await axios.put(`/api/trend/${trendId}`, formData);
      toast.success('Trend updated successfully!');
      onClose(); // Close modal after success
    } catch (error) {
      toast.error('Error updating trend.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
     <DialogTitle>
  Edit Trend
  <IconButton
    aria-label="close"
    onClick={() => {
 
      onClose(); // This should trigger the function passed as the onClose prop
    }}
    sx={{ position: 'absolute', right: 8, top: 8 }}
  >
    <CloseIcon />
  </IconButton>
</DialogTitle>


      <DialogContent>
        <FormContainer onSubmit={handleSubmit}>
          <ToastContainer />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Trend Name"
                fullWidth
                name="name"
                value={trend.name}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" component="label" startIcon={<SaveIcon />}>
                Upload Image
                <input
                  type="file"
                  name="media"
                  hidden
                  onChange={handleInputChange}
                  accept="image/*"
                  multiple
                />
              </Button>
              {/* Display the name of the uploaded file */}
              {trend.mediaName && (
                <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                  Selected File(s): {trend.mediaName}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                freeSolo
                options={availableCategories.map((option) => option.name)}
                value={trend.category}
                onChange={handleCategoryChange}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={index}
                      label={typeof option === 'string' ? option : option.name}
                      {...getTagProps({ index })}
                      deleteIcon={<CloseIcon />}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField {...params} label="Add/Select Categories" />
                )}
              />
            </Grid>
          </Grid>
        </FormContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          type="submit"
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={loading}
          startIcon={loading ? <CircularProgress size={20} /> : <SaveIcon />}
        >
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditTrendForm;
