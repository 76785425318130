import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  CircularProgress,
  Fade,
  Chip,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Box,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SaveIcon from '@mui/icons-material/Save';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import ImageIcon from '@mui/icons-material/Image';
import CategoryIcon from '@mui/icons-material/Category';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

const FormContainer = styled(Paper)({
  padding: '32px',
  marginTop: '32px',
  backgroundColor: '#f5f5f5',
  borderRadius: '15px',
  boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s ease-in-out',
  overflowY: 'auto',
  maxHeight: '90vh',
  color: '#333',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 15px 30px rgba(0, 0, 0, 0.2)',
  },
  '& .MuiTextField-root': {
    margin: '8px',
    backgroundColor: '#fff',
    borderRadius: '5px',
  },
  '& .MuiInputLabel-root': {
    color: '#333',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#ccc',
    },
    '&:hover fieldset': {
      borderColor: '#888',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#333',
    },
  },
  '& .MuiButton-root': {
    margin: '24px 8px 8px',
  },
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f5f5f5',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '10px',
  },
});

const StyledButton = styled(Button)({
  color: 'white',
  '&:hover': {
    backgroundImage: 'linear-gradient(315deg, #ff9800, #ff5722)',
  },
  '&.Mui-disabled': {
    background: 'rgba(255, 152, 0, 0.5)',
  },
});

const validateFile = (file) => {
  const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];

  if (validImageTypes.includes(file.type)) {
    return true;
  }
  return false;
};

const AddTrendForm = () => {
  const [trend, setTrend] = useState({
    name: '',
    media: null,
    mediaName: '',
    category: [],
  });
  const [availableCategories, setAvailableCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [newCategory, setNewCategory] = useState('');
  const [categoryToDelete, setCategoryToDelete] = useState('');

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('/api/trend-categories');
        setAvailableCategories(response.data);
      } catch (error) {
        toast.error('Error fetching categories.');
      }
    };

    fetchCategories();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    if (files && files[0]) {
      if (!validateFile(files[0])) {
        toast.error('Invalid file type. Please upload a PNG, JPEG, or JPG image.');
        return;
      }
      setTrend({
        ...trend,
        media: files,
        mediaName: Array.from(files).map((file) => file.name).join(', '),
      });
    } else {
      setTrend({
        ...trend,
        [name]: value,
      });
    }
  };

  const handleCategoryChange = (event, newValue) => {
    const newCategories = newValue.filter(
      (category) => !availableCategories.some((cat) => cat.name === category)
    );

    if (newCategories.length > 0) {
      if (newCategories[0].trim() === '') {
        toast.error('Category name cannot be empty.');
        return;
      }
      setNewCategory(newCategories[0]);
      setOpenConfirmDialog(true);
    } else {
      setTrend({
        ...trend,
        category: newValue.map((option) => (typeof option === 'string' ? option : option.name)),
      });
    }
  };

  const handleCategoryDelete = (category) => {
    setCategoryToDelete(category);
    setOpenDeleteDialog(true);
  };

  const confirmCategoryDelete = async () => {
    setOpenDeleteDialog(false);
    try {
      await axios.delete(`/api/trend-categories/delete/${categoryToDelete}`);
      setAvailableCategories(availableCategories.filter((category) => category.name !== categoryToDelete));
      setTrend({
        ...trend,
        category: trend.category.filter((cat) => cat !== categoryToDelete),
      });
      toast.success(`Category '${categoryToDelete}' deleted successfully!`);
    } catch (error) {
      toast.error(`Failed to delete category '${categoryToDelete}'.`);
    }
  };

  const handleConfirmAddCategory = async () => {
    setOpenConfirmDialog(false);
    try {
      const response = await axios.post('/api/trend-categories/add', {
        name: newCategory,
      });
      const addedCategory = response.data;
      toast.success(`Trend category '${newCategory}' added successfully!`);
      setAvailableCategories([...availableCategories, { name: newCategory }]);
      setTrend({
        ...trend,
        category: [...trend.category, newCategory],
      });
    } catch (error) {
      toast.error(`Failed to add category '${newCategory}'. It might already exist.`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Check if media is selected
    if (!trend.media) {
      toast.error('Please upload an image before submitting.');
      return;
    }
  
    setLoading(true);
    const formData = new FormData();
    formData.append('name', trend.name);
    if (trend.media) {
      Array.from(trend.media).forEach((file) => {
        formData.append('media', file);
      });
    }
    formData.append('categories', trend.category.join(','));
  
    try {
      await axios.post('/api/trend/add', formData);
      toast.success('Trend added successfully!');
      setTrend({
        name: '',
        media: null,
        mediaName: '',
        category: [],
      });
      setSuccess(true);
    } catch (error) {
      toast.error('Error adding trend.');
    } finally {
      setLoading(false);
      setTimeout(() => setSuccess(false), 3000);
    }
  };
  

  return (
    <FormContainer>
      <ToastContainer />
      <Typography variant="h4" gutterBottom color="#333" textAlign="center">
        <TextFieldsIcon /> Add Trend
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Trend Name"
              fullWidth
              name="name"
              value={trend.name}
              onChange={handleInputChange}
              required
              InputProps={{
                startAdornment: <TextFieldsIcon color="action" />,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" component="label" startIcon={<ImageIcon />}>
              Upload Image
              <input type="file" name="media" hidden onChange={handleInputChange} accept="image/*" multiple />
            </Button>

            {trend.mediaName && (
              <Typography variant="body2" color="textSecondary">
                {trend.mediaName}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              freeSolo
              options={availableCategories.map((option) => option.name)} // Map to category names
              value={trend.category}
              onChange={handleCategoryChange}
              filterSelectedOptions
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  const tagProps = getTagProps({ index });
                  const { key, ...restProps } = tagProps;
                  return (
                    <Chip
                      key={key}
                      {...restProps}
                      label={typeof option === 'string' ? option : option.name}
                      sx={{
                        borderRadius: 8,
                        color: '#1976d2',
                        margin: '4px',
                        '& .MuiChip-deleteIcon': {
                          color: '#d32f2f',
                        },
                      }}
                      deleteIcon={<CloseIcon />}
                      onDelete={() =>
                        setTrend({
                          ...trend,
                          category: trend.category.filter((cat) => cat !== option),
                        })
                      }
                    />
                  );
                })
              }
              renderOption={(props, option) => (
                <ListItem {...props} key={option}>
                  <ListItemText primary={option} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" onClick={() => handleCategoryDelete(option)}>
                      <DeleteIcon color="error" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )}
              renderInput={(params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <CategoryIcon color="action" sx={{ mr: 1 }} />
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Add/Select Category"
                    placeholder="Type and press enter to add"
                    sx={{ flexGrow: 1 }}
                    InputLabelProps={{
                      style: { color: '#1976d2' },
                    }}
                  />
                  <SearchIcon sx={{ ml: 1, color: '#1976d2' }} />
                </Box>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledButton
              type="submit"
              variant="contained"
              disabled={loading}
              fullWidth
              endIcon={loading ? <CircularProgress size={24} /> : <SaveIcon />}
            >
              {loading ? 'Submitting...' : 'Submit'}
            </StyledButton>
          </Grid>
        </Grid>
      </form>
      {success && (
        <Fade in={success}>
          <Typography variant="h6" sx={{ color: '#388e3c', mt: 2 }}>
            <CheckCircleOutlineIcon /> Trend added successfully!
          </Typography>
        </Fade>
      )}
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">Add New Category</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            Are you sure you want to add the category "{newCategory}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmAddCategory} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Delete Category</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete the category "{categoryToDelete}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmCategoryDelete} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </FormContainer>
  );
};

export default AddTrendForm;
