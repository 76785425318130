import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Autocomplete, Chip } from '@mui/material';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';

const EditNewsForm = ({ open, onClose, newsId, handleUpdate }) => {
  const [news, setNews] = useState({
    title: '',
    description: '',
    media: null,
    mediaName: '',
    categories: [],
    credibility: ''
  });
  const [availableCategories, setAvailableCategories] = useState([]); // State to hold available categories
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch news details by ID
    const fetchNewsDetails = async () => {
      try {
        const response = await axios.get(`/api/news/${newsId}`);
        const newsData = response.data;
        setNews({
          title: newsData.title,
          description: newsData.description,
          media: null,
          mediaName: newsData.media || '',
          categories: newsData.categories.split(','), // Ensure categories are split into an array
          credibility: newsData.credibility
        });
      } catch (error) {
        toast.error('Error fetching news details.');
      }
    };

    if (newsId) {
      fetchNewsDetails();
    }

    // Fetch available categories and ensure they are formatted correctly
    const fetchCategories = async () => {
      try {
        const response = await axios.get('/api/news-categories');
        // Ensure the categories are objects with a 'name' property
        const formattedCategories = response.data.map((cat) => ({ name: cat }));
        setAvailableCategories(formattedCategories);
      } catch (error) {
        toast.error('Error fetching categories.');
      }
    };

    fetchCategories();
  }, [newsId]);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files && files[0]) {
      setNews({
        ...news,
        media: files,
        mediaName: Array.from(files).map((file) => file.name).join(', '),
      });
    } else {
      setNews({
        ...news,
        [name]: value,
      });
    }
  };

  const handleCategoryChange = (event, newValue) => {
    setNews({
      ...news,
      categories: newValue.map((option) => {
        if (typeof option === 'string') {
          return option; // If it's a string, just return it
        }
        return option.name; // If it's an object, return its name
      }),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append('title', news.title);
    formData.append('description', news.description);
    if (news.media) {
      Array.from(news.media).forEach((file) => {
        formData.append('media', file);
      });
    }
    formData.append('categories', news.categories.join(',')); // Join categories array into a string
    formData.append('credibility', news.credibility);

    try {
      await axios.put(`/api/news/${newsId}`, formData);
      toast.success('News updated successfully!');
      onClose();
      handleUpdate(); // Refresh the list after update
    } catch (error) {
      toast.error('Error updating news.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Edit News
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Title"
              fullWidth
              name="title"
              value={news.title}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Description"
              fullWidth
              multiline
              name="description"
              value={news.description}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" component="label">
              Upload Media
              <input
                type="file"
                hidden
                name="media"
                onChange={handleInputChange}
                accept="image/*,video/*"
                multiple
              />
            </Button>
            {news.mediaName && <p>Selected file(s): {news.mediaName}</p>}
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              freeSolo
              options={availableCategories.map((option) => option.name)}
              value={news.categories}
              onChange={handleCategoryChange}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={index}
                    label={typeof option === 'string' ? option : option.name}
                    {...getTagProps({ index })}
                    deleteIcon={<CloseIcon />}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} label="Add/Select Categories" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Credibility"
              fullWidth
              name="credibility"
              value={news.credibility}
              onChange={handleInputChange}
              required
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={loading}
          startIcon={loading ? <CircularProgress size={20} /> : null}
        >
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditNewsForm;
