// ArticleList.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Grid, Card, CardContent, CardMedia, Typography, Pagination, PaginationItem, Box, useTheme, Chip, Stack, IconButton, TextField, Button, Paper, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Article as ArticleIcon, MoreVert } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import { format } from 'date-fns';
import { FirstPage, LastPage, ArrowBack, ArrowForward } from '@mui/icons-material';
import EditArticleForm from './EditArticleForm'; // Import the edit form
import { toast } from 'react-toastify'; // Import toast notifications

const ArticleList = () => {
  const theme = useTheme();
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 6;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  
  // Menu state for the three-dot button
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedArticleId, setSelectedArticleId] = useState(null);

  const open = Boolean(anchorEl);
  
  // Dialog state for editing an article
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editingArticle, setEditingArticle] = useState(null);

  // Dialog state for confirming delete
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  
  useEffect(() => {
    axios.get('/api/article')
      .then(response => {
        const sortedArticles = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setArticles(sortedArticles);
        setFilteredArticles(sortedArticles);
        setLoading(false);
      })
      .catch(error => console.error('Error fetching articles:', error));
  }, []);
  
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  
  const handleKeywordChange = (event) => {
    setKeyword(event.target.value);
    filterArticles(event.target.value, startDate, endDate);
    setCurrentPage(1);
  };

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    filterArticles(keyword, start, end);
    setCurrentPage(1);
  };

  const clearFilters = () => {
    setKeyword('');
    setStartDate('');
    setEndDate('');
    setFilteredArticles(articles);
    setCurrentPage(1);
  };

  const filterArticles = (keyword, start, end) => {
    let filteredItems = articles;

    if (keyword) {
      filteredItems = filteredItems.filter((article) =>
        article.description.toLowerCase().includes(keyword.toLowerCase()) ||
        article.title.toLowerCase().includes(keyword.toLowerCase())
      );
    }

    if (start && end) {
      const startDateObj = new Date(start);
      const endDateObj = new Date(end);
      filteredItems = filteredItems.filter((article) => {
        const articleDate = new Date(article.date);
        return articleDate >= startDateObj && articleDate <= endDateObj;
      });
    }

    setFilteredArticles(filteredItems);
  };

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = filteredArticles.slice(indexOfFirstArticle, indexOfLastArticle);

  // Handle three-dot button (MoreVert)
  const handleMenuOpen = (event, articleId) => {
    setAnchorEl(event.currentTarget);
    setSelectedArticleId(articleId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedArticleId(null);
  };

  // Open edit dialog
  const handleEdit = (article) => {
    console.log("Editing Article:", article);
    setEditingArticle(article);
    setOpenEditDialog(true);
    handleMenuClose();
  };

  // Open delete confirmation dialog
  const handleDeleteConfirmation = (articleId) => {
    setSelectedArticleId(articleId);
    setOpenDeleteDialog(true);
  };

  // Handle delete action
  const handleDelete = () => {
    axios.delete(`/api/article/${selectedArticleId}`)
      .then(() => {
        // Remove the deleted article from the list
        setArticles(articles.filter(article => article.id !== selectedArticleId));
        setFilteredArticles(filteredArticles.filter(article => article.id !== selectedArticleId));
        setOpenDeleteDialog(false);
        toast.success('Article deleted successfully!');
      })
      .catch(error => {
        console.error('Error deleting article:', error);
        toast.error('Error deleting article.');
      });
  };

  const handleUpdate = () => {
    axios.get('/api/article')
      .then(response => {
        const sortedArticles = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setArticles(sortedArticles);
        setFilteredArticles(sortedArticles);
      })
      .catch(error => console.error('Error fetching articles:', error));
    setOpenEditDialog(false);
  };

  return (
    <>
      <Container sx={{ marginTop: '5%', pb: '5%', position: 'relative', minHeight: '80vh' }}>
        <Box display="flex" alignItems="center" justifyContent="center" mb={4}>
          <ArticleIcon sx={{ mr: 2, fontSize: '2rem', color: '#FFD700' }} />
          <Typography variant="h4" component="h2" gutterBottom sx={{ color: '#FFD700', fontWeight: 'bold', letterSpacing: '2px', textTransform: 'uppercase' }}>
            All Situational Awareness
          </Typography>
        </Box>

        <Paper elevation={6} sx={{ p: 3, mb: 4, backgroundColor: '#1b1b1b', borderRadius: '16px' }}>
          <Typography variant="h6" gutterBottom sx={{ color: '#FFD700' }}>Filters</Typography>
          <Box display="flex" justifyContent="center" alignItems="center" flexWrap="wrap" gap={3}>
            <TextField
              label="Search by keyword"
              variant="outlined"
              value={keyword}
              onChange={handleKeywordChange}
              sx={{
                width: '300px',
                backgroundColor: '#2b2b2b',
                borderRadius: '8px',
                input: { color: '#FFD700' },
                label: { color: '#FFD700' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#FFD700',
                  },
                  '&:hover fieldset': {
                    borderColor: '#FFA500',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#FFD700',
                  },
                },
              }}
            />
            <TextField
              label="Start Date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={startDate}
              onChange={(e) => handleDateChange(e.target.value, endDate)}
              sx={{
                width: '200px',
                backgroundColor: '#2b2b2b',
                borderRadius: '8px',
                input: { color: '#FFD700' },
                label: { color: '#FFD700' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#FFD700',
                  },
                  '&:hover fieldset': {
                    borderColor: '#FFA500',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#FFD700',
                  },
                },
              }}
            />
            <TextField
              label="End Date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={endDate}
              onChange={(e) => handleDateChange(startDate, e.target.value)}
              sx={{
                width: '200px',
                backgroundColor: '#2b2b2b',
                borderRadius: '8px',
                input: { color: '#FFD700' },
                label: { color: '#FFD700' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#FFD700',
                  },
                  '&:hover fieldset': {
                    borderColor: '#FFA500',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#FFD700',
                  },
                },
              }}
            />
            <Button
              variant="contained"
              onClick={clearFilters}
              sx={{
                backgroundColor: '#333',
                color: '#FFD700',
                borderRadius: '8px',
                px: 3,
                py: 1,
                '&:hover': {
                  backgroundColor: '#444',
                },
              }}
            >
              Clear Filters
            </Button>
          </Box>
        </Paper>

        <Grid container spacing={4}>
          {loading ? (
            Array.from(new Array(articlesPerPage)).map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Skeleton variant="rectangular" height={250} animation="wave" />
              </Grid>
            ))
          ) : (
            currentArticles.map((article) => (
              <Grid item xs={12} sm={6} md={4} key={article.id}>
                <Card sx={{
                  backgroundColor: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                  borderRadius: '12px',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: theme.shadows[5],
                  }
                }}>
                  <CardMedia
                    component="img"
                    height="200"
                    image={`https://smmosaic.com/${article.image}`}
                    alt={article.title}
                    sx={{
                      objectFit: 'fill',
                      borderRadius: '12px 12px 0 0',
                      transition: 'transform 0.5s ease-in-out',
                      '&:hover': {
                        transform: 'scale(1.05)',
                      }
                    }}
                  />
                  <CardContent sx={{ paddingBottom: '16px', position: 'relative' }}>
                    <Typography gutterBottom variant="h6" component="div" sx={{ fontWeight: 'bold', color: '#000000' }}>
                      {article.title}
                    </Typography>
                    <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
                      <Typography variant="body2" color="#333333">
                        {article.author} - {format(new Date(article.date), 'MMMM d, yyyy')}
                      </Typography>
                    </Stack>
                    <Typography variant="body2" sx={{ height: 60, overflow: 'hidden', textOverflow: 'ellipsis', color: '#555555' }}>
                      {article.description}
                    </Typography>
                    <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
                      {article.tags && article.tags.map((tag, index) => (
                        <Chip key={`${tag}-${index}`} label={tag} size="small" sx={{ bgcolor: theme.palette.primary.light, color: theme.palette.primary.contrastText }} />
                      ))}
                    </Stack>

                    {/* Three-dot button for edit/delete */}
                    <IconButton
                      sx={{ position: 'absolute', bottom: 8, right: 8 }}
                      onClick={(e) => handleMenuOpen(e, article.id)}
                    >
                      <MoreVert />
                    </IconButton>
                  </CardContent>
                </Card>

                {/* Menu for Edit/Delete */}
                <Menu
                  anchorEl={anchorEl}
                  open={open && selectedArticleId === article.id}
                  onClose={handleMenuClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <MenuItem onClick={() => handleEdit(article)}>Edit</MenuItem>
                  <MenuItem onClick={() => handleDeleteConfirmation(article.id)}>Delete</MenuItem>
                </Menu>
              </Grid>
            ))
          )}
        </Grid>

        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 4,
          position: 'absolute',
          bottom: 16,
          left: 16,
          right: 16
        }}>
          <Pagination
            count={Math.ceil(filteredArticles.length / articlesPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            renderItem={(item) => {
              let label;
              let icon;

              switch (item.type) {
                case 'first':
                  label = 'First';
                  icon = <FirstPage sx={{ color: 'white' }} />;
                  break;
                case 'previous':
                  label = 'Previous';
                  icon = <ArrowBack sx={{ color: 'white' }} />;
                  break;
                case 'next':
                  label = 'Next';
                  icon = <ArrowForward sx={{ color: 'white' }} />;
                  break;
                case 'last':
                  label = 'Last';
                  icon = <LastPage sx={{ color: 'white' }} />;
                  break;
                default:
                  label = item.page;
                  break;
              }

              return (
                <PaginationItem
                  {...item}
                  sx={{
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    '&.MuiPaginationItem-root': {
                      color: 'white',
                    },
                  }}
                  label={
                    <>
                      {icon}
                      <span style={{ marginLeft: '4px', color: 'white' }}>{label}</span>
                    </>
                  }
                />
              );
            }}
          />
        </Box>
      </Container>
      
      {/* Edit Article Dialog */}
      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)} fullWidth maxWidth="md">
        {editingArticle && (
          <EditArticleForm
            article={editingArticle}
            onClose={() => setOpenEditDialog(false)}
            handleUpdate={handleUpdate}
          />
        )}
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this article?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="secondary">Cancel</Button>
          <Button onClick={handleDelete} color="primary">Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ArticleList;
