import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar, Divider, Typography, Hidden, IconButton } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ListIcon from '@mui/icons-material/List';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import PeopleIcon from '@mui/icons-material/People';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const drawerWidth = 240;
const collapsedWidth = 60;

const Sidebar = ({ open, handleDrawerToggle }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const location = useLocation();

  const menuItems = [
    { text: 'Dashboard', icon: <DashboardIcon />, to: '/admin' },
    { text: 'Add Situational Awareness', icon: <AddIcon />, to: '/admin/add-article' },
    { text: 'View Situational Awareness', icon: <ListIcon />, to: '/admin/articles' },
    { text: 'Add News', icon: <NewspaperIcon />, to: '/admin/add-news' },
    { text: 'View News', icon: <ListIcon />, to: '/admin/news-list' },
    { text: 'Add Trend', icon: <TrendingUpIcon />, to: '/admin/add-trend' },
    { text: 'View Trends', icon: <ListIcon />, to: '/admin/trend-list' },
    { text: 'Add User', icon: <PeopleIcon />, to: '/admin/add-user' },
    { text: 'User Management', icon: <PeopleIcon />, to: '/admin/manage-user' },
  ];

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      {/* Temporary drawer for mobile */}
      <Hidden lgUp>
        <Drawer
          variant="temporary"
          open={open}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
         
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              background: 'linear-gradient(135deg, #0d1321 0%, #3f4c6b 50%, #0d1321 100%)',
              color: '#fff',
              transition: 'transform 0.3s ease-in-out',
            },
          }}
        >
          <Toolbar>
            <Typography variant="h6" noWrap component="div" sx={{ fontWeight: 'bold' }}>
              Admin Panel
            </Typography>
          </Toolbar>
          <Divider />
          <List>
            {menuItems.map((item, index) => (
              <ListItem
                button
                key={index}
                component={Link}
                to={item.to}
                sx={{
                  width: '95%',
                  backgroundColor: location.pathname === item.to ? '#3f51b5' : 'transparent',
                  color: '#fff',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    transition: 'transform 0.3s ease-in-out',
                  },
                  '& .MuiListItemIcon-root': {
                    color: '#fff',
                  },
                }}
              >
                <ListItemIcon sx={{ color: '#fff' }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
        </Drawer>
      </Hidden>

      {/* Permanent drawer for larger screens */}
      <Hidden lgDown>
        <Drawer
          variant="permanent"
          open={isSidebarOpen}
          sx={{
            width: isSidebarOpen ? drawerWidth : collapsedWidth,
            flexShrink: 0,
             // Adjusted top position for the sidebar
            '& .MuiDrawer-paper': {
              width: isSidebarOpen ? drawerWidth : collapsedWidth,
              top: '9%',
              boxSizing: 'border-box',
              background: 'linear-gradient(135deg, #0d1321 0%, #3f4c6b 50%, #0d1321 100%)',
              color: '#fff',
              transition: 'width 0.3s ease-in-out',
            },
          }}
        >
          <Toolbar >
            <IconButton onClick={toggleSidebar}>
              {isSidebarOpen ? <ChevronLeftIcon sx={{ color: '#fff' }} /> : <ChevronRightIcon sx={{ color: '#fff' }} />}
            </IconButton>
            {isSidebarOpen && (
              <Typography variant="h6" noWrap component="div" sx={{ fontWeight: 'bold', marginLeft: 2 }}>
                Admin Panel
              </Typography>
            )}
          </Toolbar>
          <Divider />
          <List>
            {menuItems.map((item, index) => (
              <ListItem
                button
                key={index}
                component={Link}
                to={item.to}
                sx={{
                  width: '95%',
                  backgroundColor: location.pathname === item.to ? '#3f51b5' : 'transparent',
                  color: '#fff',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    transition: 'transform 0.3s ease-in-out',
                  },
                  '& .MuiListItemIcon-root': {
                    color: '#fff',
                  },
                }}
              >
                <ListItemIcon sx={{ minWidth: '50px', color: '#fff' }}>
                  {item.icon}
                </ListItemIcon>
                {isSidebarOpen && <ListItemText primary={item.text} />}
              </ListItem>
            ))}
          </List>
        </Drawer>
      </Hidden>
    </>
  );
};

export default Sidebar;
