import React from 'react';
import { Box } from '@mui/material';
import { styled, keyframes } from '@mui/system'; // Import keyframes
import logo from '../../assets/logoblue.png'; // Replace with your actual logo path

// Define the spinning animation using keyframes
const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
`;

const LogoContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '55px',  // Adjust based on the height of your navbar
  left: '-5px',
  zIndex: 1000,
  width: '180px',  // Increased size
  height: '190px', // Increased size
  backgroundImage: `url(${logo})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
 
  borderRadius: '50%',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  animation: `${spinAnimation} 10s linear infinite`, // Apply spinning animation
  '&:hover': {
    transform: 'translateY(-2px)',
 
  },
}));

const StickyLogo = () => {
  return <LogoContainer />;
};

export default StickyLogo;
