import React from 'react';
import { Navigate } from 'react-router-dom';

// Create a ProtectedRoute component
const ProtectedRoute = ({ role, children }) => {
  const userRole = localStorage.getItem('userRole');
  const authToken = localStorage.getItem('authToken');

  // If no token or no matching role, redirect to login
  if (!authToken || userRole !== role) {
    return <Navigate to="/" replace />;
  }

  // If role matches, allow access
  return children;
};

export default ProtectedRoute;
