import React, { useRef, useEffect, useState } from 'react';
import UserNavbar from './UserNavbar';
import { Box, Typography, Paper, Avatar, Grid } from '@mui/material';
import Slider from './Slider';
import AnnouncementStrip from './AnnouncementStrip';
import NewsSlider from './NewsSlider';
import TrendsSlider from './TrendsSlider'; 
import { styled } from '@mui/system';
import StickyLogo from './StickyLogo'; // Import the StickyLogo component
import axios from 'axios';
import VisibilityIcon from '@mui/icons-material/Visibility';

const Section = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 2),
  borderRadius: '12px',
  marginBottom: theme.spacing(4),
  boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)',
  }
}));

const ViewCountContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2), // Adjust padding as needed
  borderRadius: '15px', // Adjusted border radius
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'transparent' , // Set background color to transparent
  boxShadow: 'none', // Remove the box shadow
  animation: 'pulse 1.5s infinite',
}));


const ViewCountText = styled(Typography)({
  fontSize: '2rem', // Adjusted font size
  fontWeight: 700,
  color: '#FFFFFF',
  textAlign: 'center',
  marginLeft: '10px',
  '@media (max-width: 600px)': {
    fontSize: '1.5rem', // Adjust font size for mobile
  },
});

const UserDashboard = () => {
  const sliderRef = useRef(null);
  const newsSliderRef = useRef(null);
  const trendsSliderRef = useRef(null);

  const [isUserActive, setIsUserActive] = useState(true);
  const [mouseMoving, setMouseMoving] = useState(false); // New state to track mouse movement
  const inactivityTimeout = 10000; // 10 seconds of inactivity

  const [viewCount, setViewCount] = useState(0); // State to track total view count

  // Function to smooth scroll to a section with a given ref and optional offset for headings
  const scrollToSection = (ref) => {
    if (ref.current) {
      const yOffset = -100; // Adjust offset to make sure heading is visible
      const y = ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  // Auto-scroll logic with advanced smooth transitions and looping
  useEffect(() => {
    let autoScrollInterval;
    let userActivityTimeout;

    const resetAutoScroll = () => {
      clearTimeout(userActivityTimeout);
      clearInterval(autoScrollInterval);
      setIsUserActive(true);
      setMouseMoving(false); // Reset mouse movement state

      userActivityTimeout = setTimeout(() => {
        setIsUserActive(false);
      }, inactivityTimeout);
    };

    const autoScroll = async () => {
      autoScrollInterval = setInterval(async () => {
        if (!isUserActive && !mouseMoving) { // Ensure the auto-scroll only happens if mouse is inactive
          // Scroll to News Slider and wait for 10 seconds
          scrollToSection(newsSliderRef);
          await new Promise((resolve) => setTimeout(resolve, 10000));

          // Scroll to Trends Slider and wait for 10 seconds
          scrollToSection(trendsSliderRef);
          await new Promise((resolve) => setTimeout(resolve, 10000));

          // Scroll to Reports (Slider) and wait for 10 seconds
          scrollToSection(sliderRef);
          await new Promise((resolve) => setTimeout(resolve, 10000));

          // Scroll back to the top (News Slider) and repeat the cycle
          scrollToSection(newsSliderRef);
          await new Promise((resolve) => setTimeout(resolve, 10000));
        }
      }, inactivityTimeout);
    };

    autoScroll();

    const handleUserActivity = () => {
      resetAutoScroll();
    };

    const handleMouseMove = () => {
      setMouseMoving(true);
      resetAutoScroll(); // Reset the inactivity timer when mouse moves
    };

    // Add event listeners for user activity (mouse move, click, keypress, scroll)
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('keydown', handleUserActivity);
    window.addEventListener('click', handleUserActivity);
    window.addEventListener('scroll', handleUserActivity);

    // Clean up event listeners and intervals on component unmount
    return () => {
      clearTimeout(userActivityTimeout);
      clearInterval(autoScrollInterval);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('keydown', handleUserActivity);
      window.removeEventListener('click', handleUserActivity);
      window.removeEventListener('scroll', handleUserActivity);
    };
  }, [isUserActive, mouseMoving]); // Depend on mouseMoving

 useEffect(() => {
  // Function to increment the dashboard view count
  const incrementDashboardView = async () => {
    try {
      console.log('Incrementing dashboard view'); // Add logging to verify
      // Increment the total view count (global)
      await axios.post('/api/views/dashboard-view'); 
    } catch (error) {
      console.error('Error tracking dashboard view:', error);
    }
  };

  const fetchViewCount = async () => {
    try {
      console.log('Fetching view count'); // Log to see what URL is being called
      const response = await axios.get('/api/views/dashboard-view-count'); // Make sure this matches your route
  
      console.log('Response:', response.data);
      setViewCount(response.data.viewCount);
    } catch (error) {
      console.error('Error fetching view count:', error);
    }
  };
  
  
  // Call both functions once when the component mounts
  incrementDashboardView(); 
  fetchViewCount();

  // Cleanup function (optional) to handle side effects if needed
  return () => {
    console.log('Cleaning up useEffect'); 
  };
}, []); // Empty dependency array ensures this runs only once on mount

  return (
    <>
      <UserNavbar 
        scrollToSection={scrollToSection} 
        sliderRef={sliderRef} 
        newsSliderRef={newsSliderRef} 
        trendsSliderRef={trendsSliderRef} 
      />
      <StickyLogo /> {/* Add the StickyLogo component */}
      <Box mt={8} sx={{ overflowX: 'hidden', pb: '100px' }}>
        <Box>
          <AnnouncementStrip />
        </Box>

       {/* View Count Display */}
<Grid container justifyContent="end" sx={{ mt: 3, mb: 4 }}>
  <Grid item xs={10} sm={8} md={4}> {/* Adjusted Grid size for smaller display */}
    <ViewCountContainer elevation={3}> {/* Slightly reduced elevation */}
      <Avatar sx={{ width: 30, height: 30 }}> {/* Reduced Avatar size */}
        <VisibilityIcon fontSize="medium" /> {/* Smaller Icon */}
      </Avatar>
      <ViewCountText>
        {viewCount} {viewCount === 1 ? 'View' : 'Views'}
      </ViewCountText>
    </ViewCountContainer>
  </Grid>
</Grid>


        <Section ref={newsSliderRef} mt={4} mb={1}
          sx={{ 
            borderBottom: '2px solid #FFD700', 
            pt: 2, 
            pb: 2 
          }}
        >
          <NewsSlider />
        </Section>
        <Section ref={trendsSliderRef} mt={4} mb={1}
          sx={{ 
            borderBottom: '2px solid #FFD700', 
            pt: 2, 
            pb: 2 
          }}
        >
          <TrendsSlider />
        </Section> 
        <Section ref={sliderRef} mt={4} mb={1}
          sx={{ 
            borderBottom: '2px solid #FFD700', 
            pt: 2, 
            pb: 2 
          }}
        >
          <Slider />
        </Section> 
      </Box>
    </>
  );
};

export default UserDashboard;
