import React, { useState, useRef } from 'react';
import { TextField, Button, Typography, Container, Avatar, InputAdornment, Box } from '@mui/material';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './LoginPage.css';
import logo from '../../assets/logoblue.png';

// Styling for the main container to be responsive and smooth
const ContainerStyled = styled(Container)(({ isZoomed }) => ({
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: 'url("../../assets/slider1.jpg")',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  transition: 'filter 0.8s ease-in-out', // Smooth background blur
}));

const BoxContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '95%',
  maxWidth: '1100px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column', // Stack on smaller screens
  },
}));

const BoxStyled = styled(motion.div)(({ theme }) => ({
  padding: theme.spacing(6),
  backdropFilter: 'blur(15px)',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  borderRadius: '25px',
  boxShadow: '0 20px 40px rgba(0, 0, 0, 0.8)',
  border: '2px solid rgba(255, 255, 255, 0.15)',
  color: '#ffffff',
  maxWidth: '400px',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3), // Reduce padding on small screens
  },
}));

const LogoStyled = styled(motion.img)({
  maxWidth: '350px',
  '@media (max-width: 600px)': {
    maxWidth: '200px', // Reduce logo size for mobile
  },
});

const formStyles = { width: '100%' };

const avatarStyles = (theme) => ({
  margin: theme.spacing(1),
  backgroundColor: theme.palette.secondary.main,
});

const submitButtonStyles = (theme) => ({
  margin: theme.spacing(3, 0, 2),
  backgroundColor: '#1b2839',
  color: 'white',
  '&:hover': {
    backgroundColor: '#ffeb3b',
    color: '#1b2839',
  },
  [theme.breakpoints.down('sm')]: { fontSize: '0.85rem' },
});

// Validation schema
const schema = yup.object().shape({
  username: yup.string().required('Username is required'),
  password: yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
});

const LoginPage = () => {
  const navigate = useNavigate();
  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const [isZoomed, setIsZoomed] = useState(false); // Track zoom state for form and logo

  // Handle clicking outside to close zoom
  const formRef = useRef(null);
  const handleClickOutside = (event) => {
    if (formRef.current && !formRef.current.contains(event.target)) {
      setIsZoomed(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Base64 encoding function for credentials
  const encodeCredentials = (data) => {
    const jsonString = JSON.stringify(data);
    return btoa(jsonString); // Encode to Base64
  };

  const onSubmit = async (data) => {
    try {
      const encodedCredentials = encodeCredentials(data); // Encode the credentials
      const response = await axios.post('/api/user/login', { encodedCredentials }, { withCredentials: true });

      const { token, role } = response.data;

      localStorage.setItem('authToken', token);
      localStorage.setItem('userRole', role);

      toast.success('Login successful!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      setTimeout(() => {
        if (role === 'admin') {
          navigate('/admin');
        } else {
          navigate('/user');
        }
      }, 3000);
    } catch (error) {
      toast.error('Invalid username or password', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <ContainerStyled
      isZoomed={isZoomed}
      component={motion.div}
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.8 }}
    >
      <ToastContainer />
      <BoxContainer>
        <BoxStyled
          ref={formRef}
          initial={{ scale: 1 }}
          animate={{ scale: isZoomed ? 1.2 : 1 }}
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          onClick={() => setIsZoomed(true)}
        >
          <Avatar sx={avatarStyles}>
            <LockOutlinedIcon style={{ color: '#ffffff' }} />
          </Avatar>
          <Typography component="h1" variant="h5" sx={{ marginBottom: 2, textAlign: 'center' }}>
            Sign in
          </Typography>
          <form style={formStyles} noValidate onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="username"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  autoComplete="username"
                  autoFocus
                  error={!!errors.username}
                  helperText={errors.username ? errors.username.message : ''}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircleIcon style={{ color: '#ffffff' }} />
                      </InputAdornment>
                    ),
                    style: { color: '#ffffff' },
                  }}
                  InputLabelProps={{ style: { color: '#ffffff' } }}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  error={!!errors.password}
                  helperText={errors.password ? errors.password.message : ''}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <VpnKeyIcon style={{ color: '#ffffff' }} />
                      </InputAdornment>
                    ),
                    style: { color: '#ffffff' },
                  }}
                  InputLabelProps={{ style: { color: '#ffffff' } }}
                />
              )}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={submitButtonStyles}
              endIcon={<ArrowForwardIcon />}
            >
              Sign In
            </Button>
          </form>
        </BoxStyled>
        <LogoStyled
          src={logo}
          alt="Logo"
          initial={{ scale: 1 }}
          animate={{ scale: isZoomed ? 0.8 : 1 }}
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
        />
      </BoxContainer>
    </ContainerStyled>
  );
};

export default LoginPage;
