import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardContent, CardMedia, Typography, Container, Box, IconButton, Chip, Stack, Button, Menu, MenuItem } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos, Newspaper as NewspaperIcon, ViewList as ViewListIcon, MoreVert as MoreVertIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import NewsModal from './NewsModal';  // Import the NewsModal component

const NewsSlider = () => {
  const [newsItems, setNewsItems] = useState([]);
  const [filteredNewsItems, setFilteredNewsItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [open, setOpen] = useState(false);
  const [selectedNews, setSelectedNews] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null); // Anchor element for dropdown menu
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNewsItems = async () => {
      try {
        const newsResponse = await axios.get('/api/news');
        const sortedNewsItems = newsResponse.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        const latestNewsItems = sortedNewsItems.slice(0, 5); // Get the latest 5 items
        const uniqueCategories = [...new Set(newsResponse.data.flatMap(item => item.categories.split(',')))];
        setCategories(['All', ...uniqueCategories]);
        setNewsItems(latestNewsItems); // Use only the latest 5 items
        setFilteredNewsItems(latestNewsItems); // Use only the latest 5 items
      } catch (error) {
        console.error('Error fetching news items:', error);
      }
    };
    fetchNewsItems();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % filteredNewsItems.length);
    }, 7000); // Slide transition every 7 seconds
    return () => clearInterval(interval);
  }, [filteredNewsItems.length]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + filteredNewsItems.length) % filteredNewsItems.length);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % filteredNewsItems.length);
  };

  const handleNewsClick = (newsItem) => {
    setSelectedNews(newsItem);
    setOpen(true);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setAnchorEl(null); // Close dropdown menu when a category is selected
    if (category === 'All') {
      setFilteredNewsItems(newsItems);
    } else {
      const filteredItems = newsItems.filter((newsItem) =>
        newsItem.categories.split(',').includes(category)
      );
      setFilteredNewsItems(filteredItems);
    }
    setCurrentIndex(0);  // Reset slider to the first item
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedNews(null);
  };

  const handleShowAllNews = () => {
    navigate('/news');  // Redirect to the news page
  };

  const getCategoryStyles = (category) => {
    if (category.includes('National')) {
      return {
        cardBackground: 'linear-gradient(135deg, #004d00, #006600)',  // Dark green gradient
        chipBackground: '#006600',
        color: '#FFFFFF',
      };
    }
    if (category.includes('Regional')) {
      return {
        cardBackground: 'linear-gradient(135deg, #004d4d, #008080)',  // Teal gradient
        chipBackground: '#008080',  // Teal background for the chip
        color: '#FFFFFF',  // White text color
      };
    }
    
    if (category.includes('International')) {
      return {
        cardBackground: 'linear-gradient(135deg, #000000, #434343)',  // Black gradient
        chipBackground: '#1d2f5d',
        color: '#FFFFFF',
      };
    }
    return {
      cardBackground: 'linear-gradient(135deg, #434343, #000000)',  // Default black gradient
      chipBackground: '#434343',
      color: '#FFFFFF',
    };
  };

  const renderMedia = (mediaUrl) => {
    const mediaExtension = mediaUrl.split('.').pop().toLowerCase();
    if (['mp4', 'webm', 'ogg'].includes(mediaExtension)) {
      return (
        <CardMedia
          component="video"
          height="180"
          controls
          src={`https://smmosaic.com/uploads/${mediaUrl}`}
          alt="video"
          className="card-media"
          sx={{ borderRadius: '8px 8px 0 0' }}
        />
      );
    } else {
      return (
        <CardMedia
          component="img"
          height="180"
          image={`https://smmosaic.com/uploads/${mediaUrl}`}
          alt="image"
          className="card-media"
          sx={{ borderRadius: '8px 8px 0 0' }}
        />
      );
    }
  };

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget); // Set the anchor element for the dropdown menu
  };

  const handleMenuClose = () => {
    setAnchorEl(null); // Close the dropdown menu
  };

  return (
    <Container>
      <Box display="flex" alignItems="center" justifyContent="center" mb={4}>
        <NewspaperIcon sx={{ mr: 2, fontSize: '2rem', color: '#FFD700' }} />
        <Typography variant="h4" component="h2" gutterBottom sx={{ color: '#FFD700', fontWeight: 'bold', letterSpacing: '2px', textTransform: 'uppercase' }}>
          Latest Developments
        </Typography>
      </Box>
      <Box position="relative" className="slider-container">
        <IconButton
          onClick={handlePrev}
          className="slider-button"
          sx={{ position: 'absolute', top: '50%', left: 0,  color: '#FFD700' }}
        >
          <ArrowBackIos />
        </IconButton>
        <Box
  display="flex"
  justifyContent="center"
  alignItems="center"
  position="absolute"
  left="35%"
  transform="translateX(-50%)"
  zIndex={1}
  bgcolor="rgba(0, 0, 0, 0.7)"
  p={1}
  borderRadius="10px"
>
  <Stack direction="row" spacing={1}>
    {categories.slice(0, 3).map((category) => {
      const { chipBackground, color } = getCategoryStyles(category);  // Get the background and text color for each category
      return (
        <Chip
          key={category}
          label={category}
          onClick={() => handleCategoryChange(category)}
          sx={{
            backgroundColor: chipBackground,  // Always apply the category's specific background color
            color: color,  // Always apply the category's specific text color
            fontWeight: selectedCategory === category ? 'bold' : 'normal',
            cursor: 'pointer',
            borderRadius: '8px',
            px: 2,
            py: 1,
            '&:hover': {
              transform: 'scale(1.05)',  // Slight hover effect for interaction feedback
            },
          }}
        />
      );
    })}
    {categories.length > 3 && (
      <>
        <IconButton
          onClick={handleMoreClick}
          sx={{
            color: '#FFD700',
            borderRadius: '8px',
            padding: '6px',
            backgroundColor: '#333',
            '&:hover': {
              backgroundColor: '#444',
            },
          }}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          PaperProps={{
            style: {
              backgroundColor: '#333',
              color: '#FFD700',
            },
          }}
        >
          {categories.slice(3).map((category) => {
            const { chipBackground, color } = getCategoryStyles(category);  // Same logic for the dropdown menu
            return (
              <MenuItem
                key={category}
                onClick={() => handleCategoryChange(category)}
                sx={{
                  backgroundColor: chipBackground,  // Always apply the category's specific background color
                  color: color,  // Always apply the category's specific text color
                  fontWeight: selectedCategory === category ? 'bold' : 'normal',
                  '&:hover': {
                    backgroundColor: chipBackground,  // Keep the same background color on hover
                    opacity: 0.9,  // Slight hover effect
                  },
                }}
              >
                {category}
              </MenuItem>
            );
          })}
        </Menu>
      </>
    )}
  </Stack>
</Box>

        <Box className="slider-track" sx={{ mt: 7 }} style={{ transform: `translateX(-${currentIndex * 300}px)` }}>
          {filteredNewsItems.map((newsItem, index) => (
            <Box
              key={newsItem.id}
              className={`slider-card ${index === currentIndex ? 'active' : ''}`}
              onClick={() => handleNewsClick(newsItem)}
              sx={{
                minWidth: '300px',
                maxWidth: '300px',
                height: '350px',
                padding: '8px',
                cursor: 'pointer',
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'translateY(0px)',
                  boxShadow: '0 4px 20px rgba(0,0,0,0.5)',
                },
              }}
            >
    <Card
  className="article-card"
  sx={{
    width: '100%',
    height: '100%',
    borderRadius: '8px',
    overflow: 'hidden',
    background: getCategoryStyles(newsItem.categories).cardBackground,
    color: getCategoryStyles(newsItem.categories).color,
    position: 'relative', // Enables absolute positioning of chips
  }}
>
  <Box sx={{ position: 'relative' }}>
    {renderMedia(newsItem.media.split(',')[0])}

    {/* Category Chip - Attached to the top-right corner */}
    <Chip
      label={newsItem.categories}
      sx={{
        position: 'absolute',
        top: '0', // Stick to the top
        right: '0', // Stick to the right
        margin: '10px', // Space from the corner
        backgroundColor: getCategoryStyles(newsItem.categories).chipBackground,
        color: getCategoryStyles(newsItem.categories).color,
        fontWeight: 'bold',
        borderRadius: '12px',
        padding: '6px 12px',
        fontSize: '0.85rem',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
      }}
    />
  </Box>

  <CardContent
    className="card-content"
    sx={{
      padding: '16px',
      color: getCategoryStyles(newsItem.categories).color,
    }}
  >
    <Typography variant="body2" color="textSecondary" sx={{ color: 'inherit' }}>
      {newsItem.description.length > 80
        ? `${newsItem.description.substring(0, 80)}...`
        : newsItem.description}
    </Typography>

    {/* Credibility and Date placed below content */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '8px',
        fontSize: '16px',
        fontWeight: 'bold',
      }}
    >
      {/* Credibility Chip on the left */}
      <Chip
        label={newsItem.credibility}
        sx={{
          backgroundColor:
            newsItem.credibility === 'High'
              ? '#800000' // Dark maroon for "High"
              : newsItem.credibility === 'Medium'
              ? '#FF8C00' // Dark orange for "Medium"
              : '#006400', // Dark green for "Low",
          color: 'white',
          fontWeight: 'bold',
          borderRadius: '12px',
          padding: '6px 12px',
          fontSize: '0.85rem',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
        }}
      />

      {/* Date on the right side */}
      <Typography sx={{ color: 'white', textAlign: 'right' }}>
        {new Date(newsItem.date).toLocaleDateString()}
      </Typography>
    </Box>
  </CardContent>
</Card>



            </Box>
          ))}
        </Box>
        <IconButton
          onClick={handleNext}
          className="slider-button"
          sx={{ position: 'absolute', top: '50%', right: 0,  color: '#FFD700' }}
        >
          <ArrowForwardIos />
        </IconButton>
      </Box>
      <Box display="flex" justifyContent="center" mt={4}>
      <Button
  variant="contained"
  onClick={handleShowAllNews}
  startIcon={<ViewListIcon />}
  sx={{
    background: 'linear-gradient(135deg, #004e92, #000428)', // Gradient background
    color: '#FFFFFF', // White text
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderRadius: '25px', // Rounded corners
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)', // Adds depth
    transition: 'all 0.3s ease', // Smooth transition for hover effects
    '&:hover': {
      background: 'linear-gradient(135deg, #003366, #001f33)', // Darker gradient on hover
      boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.5)', // Stronger shadow on hover
      transform: 'scale(1.05)', // Slight scale effect on hover
    },
  }}
>
  Show All Developments
</Button>

      </Box>

      {/* News Modal */}
      <NewsModal open={open} handleClose={handleClose} newsItem={selectedNews} />
    </Container>
  );
};

export default NewsSlider;
